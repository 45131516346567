import { View, StyleSheet } from "react-native";
import { Formik, FormikHelpers } from "formik";
import {
  Button,
  FlatListItemSeparator,
  Form,
  FormikSubmit,
  FormikSelectField,
  useToast,
} from "@smartrent/ui";
import { useCallback, useState } from "react";
import * as yup from "yup";

import { Controller } from "@/modules/controller/types";
import { Site } from "@/modules/site/types";
import { useChannel } from "@/hooks/useChannel";
import {
  controllerChannelTopic,
  hubChannelTopic,
  Messages,
} from "@/lib/socket";
import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";
import { Hub } from "@/modules/hub/types";

import {
  SMRTPCommand,
  SMRTPCommandParams,
  SMRTPCommandOptions,
} from "@/modules/controller/configurations/smrtp/commands";

import {
  ControllerResponse,
  ControllerResponseRow,
} from "./shared/ControllerDebugResponse";

interface SMRTPDebugPanelProps {
  controller: Controller;
  site: Site;
}

const validationSchema = yup.object().shape({
  command: yup.string().nullable().required().label("Command"),
});

interface MPLCommandPayload {
  command: SMRTPCommand | null;
}

export const SMRTPDebugPanel = ({ controller, site }: SMRTPDebugPanelProps) => {
  const controllerChannel = useChannel(controllerChannelTopic(controller));
  const hubChannel = useChannel(hubChannelTopic(controller.hub as Hub));
  const [selectedCommand, setSelectedCommand] = useState<SMRTPCommand | null>(
    null
  );
  const [replies, setReplies] = useState<Array<ControllerResponse>>([]);
  const setToast = useToast();

  useSocketChannelEvent(controllerChannel, Messages.debug_reply, (response) => {
    if (!response.body) return;
    const temp = replies.slice();
    temp.push({
      status: response.status,
      body: JSON.stringify(response.body, null, 2),
      path: response.path,
      timestamp: new Date().toISOString(),
    });
    setReplies(temp);
  });

  useSocketChannelEvent(hubChannel, Messages.controller_reply, (response) => {
    const temp = replies.slice();
    const status =
      response.status === 1
        ? "success"
        : response.status === 0
          ? "error"
          : "info";
    temp.push({
      status,
      body: JSON.stringify(response, null, 2),
      path: response.reference,
      timestamp: new Date().toISOString(),
    });
    setReplies(temp);
  });

  const defaultFormValues = useCallback(() => {
    if (!selectedCommand)
      return {
        command: null,
        site_id: site.id,
        controller_id: controller.id,
      };
    const selectedCommandParams = SMRTPCommandParams[selectedCommand];

    if (!selectedCommandParams) return { command: null };

    return {
      command: selectedCommand,
      site_id: site.id,
      controller_id: controller.id,
    };
  }, [selectedCommand, controller.id, site.id]);

  const submitForm = useCallback(
    (
      values: MPLCommandPayload,
      formikHelpers: FormikHelpers<MPLCommandPayload>
    ) => {
      if (controllerChannel?.state !== "joined") {
        setToast({
          message: "Socket disconnected, refresh the page and try again.",
          status: "error",
        });
        formikHelpers.setSubmitting(false);
        return;
      }

      controllerChannel.push(Messages.debug_send_request, values);
      formikHelpers.setSubmitting(false);
    },
    [controllerChannel, setToast]
  );

  return (
    <View style={styles.root}>
      <View>
        <Formik<MPLCommandPayload>
          initialValues={defaultFormValues()}
          onSubmit={submitForm}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {(helpers) => {
            if (helpers.values.command != selectedCommand)
              setSelectedCommand(helpers.values.command);

            return (
              <Form>
                <View style={styles.formContainer}>
                  <View style={styles.formHStack}>
                    <FormikSelectField
                      style={styles.formHInput}
                      name="command"
                      label="Command"
                      options={SMRTPCommandOptions}
                    />
                    {replies.length > 0 && (
                      <View style={styles.formHSubmit}>
                        <Button
                          size="large"
                          onPress={() => {
                            setReplies([]);
                          }}
                        >
                          Clear
                        </Button>
                      </View>
                    )}
                    <View style={styles.formHSubmit}>
                      <FormikSubmit size="large" />
                    </View>
                  </View>
                </View>
              </Form>
            );
          }}
        </Formik>
      </View>
      <View style={styles.replies}>
        {replies.map((reply, i) => {
          return (
            <View key={i}>
              <ControllerResponseRow {...reply} />
              <FlatListItemSeparator />
            </View>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "column",
  },
  replies: {
    padding: 16,
    flexDirection: "column-reverse",
  },
  formContainer: {
    padding: 8,
  },
  formHStack: {
    flexDirection: "row",
  },
  formHInput: {
    padding: 4,
    flexGrow: 1,
  },
  formHSubmit: {
    padding: 4,
  },
});
