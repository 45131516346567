import {
  Button,
  LoadingCard,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitleWithSubtitle,
} from "@smartrent/ui";
import { useCallback, useEffect, useState } from "react";
import { FormikHelpers } from "formik";

import { controllerChannelTopic, Messages } from "@/lib/socket";

import {
  Controller,
  ControllerConfiguration,
  ControllerProtocols,
} from "@/modules/controller/types";
import { ControllerQueries } from "@/modules/controller/queries";
import { Site } from "@/modules/site/types";

import { AKVXConfig } from "@/modules/controller/configurations/akvx";

import { MPLConfig } from "@/modules/controller/configurations/mpl";

import { ASPConfig } from "@/modules/controller/configurations/asp";

import { SMRTPConfig } from "@/modules/controller/configurations/smrtp";

import { humanize } from "@/lib/helpers";

import { SHIPConfig } from "@/modules/controller/configurations/ship";

import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";

import { useChannel } from "@/hooks/useChannel";
import { AKVXControllerConfigurationForm } from "@/modules/controller/configuration-forms/AKVX";
import { MPLControllerConfigurationForm } from "@/modules/controller/configuration-forms/MPL";
import { ShipControllerConfigurationForm } from "@/modules/controller/configuration-forms/SHIP";
import { SmrtpControllerConfigurationForm } from "@/modules/controller/configuration-forms/SMRTP";
import { ErrorPage } from "@/components/ErrorPage";
import { useDialog } from "@/context/dialog";
import { ASPControllerConfigurationForm } from "@/modules/controller/configuration-forms/ASP";

interface ControllerInformationPanelProps {
  controller: Controller;
  site: Site;
}

enum ConfigurationStatus {
  pending = "Pending",
  syncing = "Syncing",
  synced = "Synced",
  error = "Error",
}

export const ControllerConfigurationPanel = ({
  controller,
  site,
}: ControllerInformationPanelProps) => {
  const { confirm } = useDialog();
  const controllerChannel = useChannel(controllerChannelTopic(controller));
  const [configurationStatus, setConfigurationStatus] = useState<
    string | ConfigurationStatus
  >(ConfigurationStatus.pending);
  const [configurationErrorReason, setConfigurationErrorReason] = useState<
    string | null
  >(null);
  const [configuration, setConfiguration] =
    useState<ControllerConfiguration | null>(null);
  const [updateControllerConfiguration] =
    ControllerQueries.useUpdateControllerConfigurationMutation();

  useSocketChannelEvent(
    controllerChannel,
    Messages.config_changed,
    (response) => {
      if (response.error) {
        setConfigurationStatus(ConfigurationStatus.error);
        setConfigurationErrorReason(humanize(response.error));
      } else {
        setConfigurationStatus(ConfigurationStatus.synced);
        setConfiguration(response);
      }
    }
  );

  const getConfig = useCallback(() => {
    setConfigurationErrorReason(null);
    setConfigurationStatus(ConfigurationStatus.pending);
    if (controllerChannel?.state == "joined") {
      controllerChannel.push(Messages.fetch_config, {});
    }
  }, [controllerChannel]);

  // Used to load the config on initial page load.
  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const handleSubmit = useCallback(
    async (values: ControllerConfiguration, helpers: FormikHelpers<any>) => {
      try {
        const confirmed = await confirm({
          title: "Update Configuration",
          description:
            controller.protocol === ControllerProtocols.mpl
              ? "This will reset the SCP controller, reboot it, and apply the new configuration. Continue?"
              : "This will apply the new configuration. Continue?",
          confirmText: "Set",
          confirmType: "primary",
        });

        if (confirmed) {
          setConfigurationStatus(ConfigurationStatus.syncing);
          await updateControllerConfiguration({
            controller_id: controller.id,
            controllerConfiguration: values,
          });
          setConfigurationStatus(ConfigurationStatus.synced);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [confirm, controller.protocol, controller.id, updateControllerConfiguration]
  );

  const RenderForm = useCallback(() => {
    if (configurationErrorReason) {
      return <ErrorPage title="Failed To Load Config" />;
    }
    if (!configuration) {
      return <LoadingCard />;
    }

    switch (controller.protocol) {
      case ControllerProtocols.mpl:
        return (
          <MPLControllerConfigurationForm
            config={configuration as MPLConfig}
            handleSubmit={handleSubmit}
          />
        );
      case ControllerProtocols.smrtp:
        return (
          <SmrtpControllerConfigurationForm
            config={configuration as SMRTPConfig}
            handleSubmit={handleSubmit}
          />
        );
      case ControllerProtocols.ship:
        return (
          <ShipControllerConfigurationForm
            config={configuration as SHIPConfig}
            handleSubmit={handleSubmit}
          />
        );
      case ControllerProtocols.akvx:
        return (
          <AKVXControllerConfigurationForm
            config={configuration as AKVXConfig}
            handleSubmit={handleSubmit}
          />
        );
      case ControllerProtocols.asp:
        return (
          <ASPControllerConfigurationForm
            config={configuration as ASPConfig}
            handleSubmit={handleSubmit}
          />
        );
    }
    return null;
  }, [
    configuration,
    controller.protocol,
    handleSubmit,
    configurationErrorReason,
  ]);

  return (
    <Panel>
      <PanelHeader
        EndAdornment={
          <Button
            onPress={() => {
              setConfiguration(null);
              getConfig();
            }}
          >
            Refresh
          </Button>
        }
      >
        <PanelHeaderTitleWithSubtitle
          title="Configuration"
          subtitle={
            configurationErrorReason
              ? `Error: ${configurationErrorReason}`
              : configurationStatus
          }
        />
      </PanelHeader>
      <PanelBody>
        <RenderForm />
      </PanelBody>
    </Panel>
  );
};
