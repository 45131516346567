import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Site } from "@/modules/site/types";

import { FirmwareSiteSettingsPage } from "./FirmwareSiteSettingsPage";
import { GeneralSiteSettingsPage } from "./GeneralSiteSettingsPage";
import { NotificationSiteSettingsPage } from "./NotificationSiteSettingsPage";
import { SiteSettingsPage } from "./SiteSettingsPage";

interface RouterProps {
  site: Site;
}

export const SiteSettingsRouter = ({ site }: RouterProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <SiteSettingsPage site={site} />
      </Route>
      <Route exact path={`${path}/general`}>
        <GeneralSiteSettingsPage site={site} />
      </Route>
      <Route exact path={`${path}/firmware`}>
        <FirmwareSiteSettingsPage site={site} />
      </Route>
      <Route exact path={`${path}/site-notifications`}>
        <NotificationSiteSettingsPage site={site} />
      </Route>
    </Switch>
  );
};
