import { useState } from "react";
import { StyleSheet } from "react-native";
import { AxiosResponse } from "axios";
import * as yup from "yup";

import {
  Button,
  Table,
  VStack,
  useToast,
  Typography,
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
} from "@smartrent/ui";
import { FormNumberInputField, Form, FormSubmit } from "@smartrent/forms";
import { useModalState } from "@smartrent/hooks";

import { Controller } from "@/modules/controller/types";
import { apiClient } from "@/lib/api";
import { useAppDrawer } from "@/components/layout/AppDrawer";
import { Reader, LEDColor, ValidSubmitKeys } from "@/modules/reader/types";
import { Panel } from "@/modules/panel/types";
import { QueryKeys } from "@/types";
import { ReaderDrawerProps } from "@/modules/reader/Drawer";

interface ControllerOsdpProps {
  controller: Controller;
}

interface AddressData {
  address: number;
  associated_reader: undefined | Reader;
  serial: string;
}

export const buildAddressOptions = (
  addressData: AddressData[],
  readerAddress: number,
  maxAddress = 127
) => {
  const usedAddresses = addressData
    .filter(({ address }: AddressData) => address !== readerAddress)
    .map(({ address }: { address: number }) => address);

  return (
    Array.from(Array(maxAddress).keys())
      // 83 is a reserved OSDP address, it should never be chosen as an address
      .filter((n) => n !== 83 && !usedAddresses.includes(n))
      .map((n) => ({ label: n, value: n }))
  );
};

export const ControllerOsdpDisplay = ({ controller }: ControllerOsdpProps) => {
  const setToast = useToast();
  const drawer = useAppDrawer();
  const {
    visible: customScanDrawerVisible,
    onOpen: openCustomScanDrawer,
    onClose: closeCustomScanDrawer,
  } = useModalState();

  const [addressData, setAddressData] = useState<AddressData[]>([]);
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [scanType, setScanType] = useState<"custom" | "full" | null>(null);
  const [maxAddress, setMaxAddress] = useState<number>(127);

  const handleSuccessScan = (response: AxiosResponse) => {
    setAddressData(response.data.address_data);
    setIsScanning(false);
    setScanType(null);
  };

  const handleErrorScan = (error: any) => {
    setIsScanning(false);
    setScanType(null);
    setToast({
      message: error.response.data.errors[0],
      status: "error",
      title: "Error",
    });
  };

  const fullScan = () => {
    // clear previous scan data
    setAddressData([]);
    setIsScanning(true);
    setScanType("full");

    setToast({
      message: "This could take a minute",
      title: "Starting Scan",
      status: "knowledge",
    });

    apiClient
      .post(`v1/controllers/${controller.id}/osdp_scan`)
      .then(handleSuccessScan)
      .catch(handleErrorScan);
  };

  const customScan = async ({ start, end }: { start: number; end: number }) => {
    // clear previous scan data
    setAddressData([]);
    setIsScanning(true);
    setScanType("custom");
    setMaxAddress(end + 1);

    // if we're scanning more than 50 addresses (arbitrary), let the
    // user know this might take a moment
    if (end - start > 50) {
      setToast({
        message: "This could take a minute",
        title: "Starting Scan",
        status: "knowledge",
      });
    }

    closeCustomScanDrawer();

    apiClient
      .post(`v1/controllers/${controller.id}/osdp_scan`, {
        start_address: start,
        end_address: end,
      })
      .then(handleSuccessScan)
      .catch(handleErrorScan);
  };

  return (
    <>
      <VStack>
        <Table<AddressData>
          title="Connected Readers"
          data={addressData}
          columns={[
            {
              name: "address",
              header: "Address",
              render: ({ row }) => <Typography>{row.address}</Typography>,
            },
            {
              name: "serial",
              header: "Serial",
              render: ({ row }) => <Typography>{row.serial}</Typography>,
            },
            {
              name: "readerName",
              header: "Associated Reader Name",
              render: ({ row }) => (
                <Typography>{row.associated_reader?.name || "N/A"}</Typography>
              ),
            },
          ]}
          onRowPress={(row) => {
            const addressOptions = buildAddressOptions(
              addressData,
              row.address,
              maxAddress
            );

            if (row.associated_reader) {
              drawer.push(QueryKeys.ReadersWarbler, {
                addressOptions,
                reader: row.associated_reader,
                afterSubmit: () => setAddressData([]),
              } as ReaderDrawerProps);
            } else {
              const panel = controller.panels[0] as Panel;
              const props = {
                addressOptions,
                // Default values for Warbler reader
                reader: {
                  address: row.address,
                  original_address: row.address,
                  led_color: LEDColor.red,
                  submit_key: ValidSubmitKeys[0],
                  pin_digits: 8,
                  panel: { ...panel, controller: controller },
                } as Reader,
                afterSubmit: () => setAddressData([]),
              } as ReaderDrawerProps;

              drawer.push(QueryKeys.ReadersWarbler, props);
            }
          }}
          action={
            <>
              <Button
                isLoading={isScanning && scanType == "full"}
                disabled={scanType == "custom"}
                onPress={fullScan}
                style={styles.fullScanButton}
              >
                Full Scan
              </Button>
              <Button
                isLoading={isScanning && scanType == "custom"}
                disabled={scanType == "full"}
                onPress={openCustomScanDrawer}
              >
                Custom Scan
              </Button>
            </>
          }
        />
      </VStack>
      <Drawer
        anchor="right"
        onClose={closeCustomScanDrawer}
        open={customScanDrawerVisible}
      >
        <>
          <DrawerHeader title="Custom Scan" />

          <Form
            initialValues={{ start: 0, end: 10 }}
            validationSchema={yup.object().shape({
              start: yup.number().required().label("Start").min(0).max(125),
              end: yup
                .number()
                .required()
                .label("End")
                .min(1)
                .max(126)
                .test(
                  "is-greater-than-start",
                  "End must be greater than Start",
                  (value, context) => {
                    if (!value) {
                      return true;
                    } else {
                      return value > context.parent.start;
                    }
                  }
                ),
            })}
            onSubmit={customScan}
          >
            <DrawerContent>
              <VStack spacing={8}>
                <FormNumberInputField name="start" label="Start" required />
                <FormNumberInputField name="end" label="End" required />
              </VStack>
            </DrawerContent>

            <DrawerActions>
              <Button
                variation="plain"
                color="grayscale"
                onPress={closeCustomScanDrawer}
              >
                Cancel
              </Button>
              <FormSubmit label="Save" />
            </DrawerActions>
          </Form>
        </>
      </Drawer>
    </>
  );
};

const styles = StyleSheet.create({
  fullScanButton: { marginRight: 4 },
});
