import { useContext } from "react";
import { View, StyleSheet } from "react-native";

import { Close } from "@smartrent/icons";
import { Button, Typography, VStack } from "@smartrent/ui";

import { PageCtx } from "@/context/PageContext";
import { useSiteAlerts } from "@/hooks/useSiteAlerts";

import { AlertIcon } from "./AlertIcon";

export const AlertBanner: React.FC = () => {
  const { site } = useContext(PageCtx);

  const { alerts, dismissAlert } = useSiteAlerts(site?.id);

  if (!alerts?.length) {
    return null;
  }

  // first alert in the list is the active alert
  const activeAlert = alerts[0];

  return (
    <View style={styles.container}>
      <AlertIcon alert={activeAlert} />
      <VStack spacing={4}>
        {!!activeAlert.items[0].title && (
          <Typography type="title5">{activeAlert.items[0].title}</Typography>
        )}
        {!!activeAlert.items[0].message && (
          <Typography type="captionLarge" style={styles.regularWeightFont}>
            {activeAlert.items[0].message}
          </Typography>
        )}
      </VStack>
      <Button
        onPress={() => dismissAlert(activeAlert.key)}
        variation="plain"
        style={styles.closeButton}
      >
        <Close />
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: "#EBEEF4",
    borderBottomWidth: 1,
    borderBottomColor: "#BEC2CD",
    flexDirection: "row",
    gap: 20,
    height: 84,
    padding: 20,
  },
  closeButton: {
    marginLeft: "auto",
  },
  regularWeightFont: {
    fontWeight: "400",
  },
});
