import {
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";
import { useCallback } from "react";

import { useParams } from "react-router-dom";

import { AccessLevel } from "@/modules/access-level/types";

import { CreateButton, createActionsColumn } from "@/modules/base/table/utils";

import { AccessLevelQueries } from "@/modules/access-level/queries";

import { QueryKeys } from "@/types";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { Member } from "../types";
import { MemberQueries } from "../queries";

interface AccessLevelMemberFilters extends Partial<AccessLevel> {
  member_id: number;
}

type AccessLevelFilters = TableFilters & AccessLevelMemberFilters;

interface AccessLevelsTableProps {
  filters: AccessLevelFilters;
  member: Member;
  params: { site_id: number };
}

export const MemberAccessLevelsTable = ({
  filters,
  params,
  member,
}: AccessLevelsTableProps) => {
  const drawer = useAppDrawer();
  const urlParams = useParams();
  const { confirm } = useDialog();
  const { canUpdate, canDelete } = usePermissions(MemberQueries);

  const [doDelete] = MemberQueries.useUnassignAccessLevelMutation();

  const onDelete = useCallback(
    async (row: AccessLevel) => {
      const confirmed = await confirm({
        title: "Remove Access Level?",
        description:
          "Are you sure you want to remove this access level from the member?",
        confirmText: "Delete",
        confirmType: "destructive",
        cancelText: "Cancel",
      });
      if (confirmed) {
        doDelete({ member_id: member?.id, access_level_id: row.id });
      }
    },
    [confirm, doDelete, member?.id]
  );

  const tableProps = useTableQuery<
    any,
    AccessLevel,
    ListQueryResponse<AccessLevel>
  >({
    fetch: AccessLevelQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      AccessLevelQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],

    columns: [
      {
        name: "name",
        header: "Access Level Name",
        render: ({ row }) => {
          return <Typography>{row.name}</Typography>;
        },
      },
      createActionsColumn<AccessLevel>({
        canDelete,
        onDelete,
      }),
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<AccessLevel>
      title={""}
      noRecordsText={"No Access Levels Found"}
      action={
        <CreateButton
          canCreate={canUpdate}
          onCreate={() =>
            drawer.push(QueryKeys.MemberAccessLevels, {
              initialValues: member,
              params: { ...urlParams, ...params },
            })
          }
          text={"Assign Access Levels"}
        />
      }
      {...tableProps}
    />
  );
};
