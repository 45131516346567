import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  ScrollView,
} from "react-native";

import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { AlertBanner } from "@/modules/alerts/AlertBanner";

import { NavDrawerContainer } from "../alloy-access/NavDrawer";
import { OrganizationBanner } from "../alloy-access/OrganizationBanner";
import { NavigationBanner } from "../alloy-access/NavigationBanner";

interface PageProps {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
}

export const Page = ({ style, children }: PageProps) => {
  const { isDesktop } = useWindowDimensionsQuery({
    isDesktop: ({ width }) => width >= 1200,
  });

  return (
    <ScrollView style={styles.scrollView}>
      <View style={[isDesktop ? styles.rootDesktop : styles.root, style]}>
        <NavDrawerContainer />
        <View style={styles.rightPane}>
          <OrganizationBanner />
          <NavigationBanner />
          <AlertBanner />
          <View style={styles.content}>{children}</View>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flexDirection: "column",
    flexGrow: 1,
  },
  root: {
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100%",
  },
  rootDesktop: {
    flexDirection: "row",
    flexGrow: 1,
  },
  rightPane: {
    flexGrow: 1,
    flexShrink: 1,
  },
  content: {},
});
