import {
  useTableQuery,
  ListQueryResponse,
  Table,
  Typography,
  Link,
} from "@smartrent/ui";

import { compact } from "lodash-es";

import { fromZonedTime } from "date-fns-tz";

import { TableFilters } from "@/modules/base/table/types";

import { DateTimeTypography } from "@/components/DateTimeTypography";

import { humanize } from "@/lib/helpers";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { Paths } from "@/lib/path";

import { Site } from "../site/types";

import { useTableUtils } from "../base/table/utils";

import { UserQueries } from "../user/queries";

import { User } from "../user/types";

import { ExternalUser, ExternalUserQueries } from "../external-users/queries";

import { UserActivityLogQueries } from "./queries";
import { UserActivityLog } from "./types";

interface UserActivityLogsTableProps {
  filters: UserActivityLogsTableFilters;
  site: Site;
}

interface UserActivityLogsTableFilters extends Partial<TableFilters & Event> {
  site_id: number;
}

const DetailsLink = ({ href, text }: { href: string; text: string }) => (
  <Link href={href} target="_blank" color="primary">
    {text}
  </Link>
);

const RenderDetails = ({
  row,
  numberOfLines,
}: {
  row: UserActivityLog;
  numberOfLines: number | undefined;
}) => {
  switch (row.category) {
    case "schedule":
      if (row?.schedule && row.site) {
        return (
          <DetailsLink
            href={Paths.scheduleViewPage(row.site.id, row.schedule.id)}
            text={row.schedule.name}
          />
        );
      } else {
        return (
          <Typography numberOfLines={numberOfLines}>
            Schedule Deleted
          </Typography>
        );
      }
    case "member_access_level":
    case "member":
      if (row?.member && row.site) {
        return (
          <DetailsLink
            href={Paths.memberViewPage(row.site.id, row.member.id)}
            text={`${row.member.first_name} ${row.member.last_name}`}
          />
        );
      } else {
        return (
          <Typography numberOfLines={numberOfLines}>Member Deleted</Typography>
        );
      }
    default:
      return (
        <Typography numberOfLines={numberOfLines}>
          {JSON.stringify(row.details).substring(0, 12)}
        </Typography>
      );
  }
};

export const UserActivityLogsTable = ({
  filters,
  site,
}: UserActivityLogsTableProps) => {
  const { numberOfLines } = useTableUtils();
  const drawer = useAppDrawer();
  const { canView } = usePermissions(UserActivityLogQueries);

  const userSelectProps = UserQueries.tableSelectProps({
    defaultParams: {},
  });

  const externalUserSelectProps = ExternalUserQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const tableProps = useTableQuery<
    any,
    UserActivityLog,
    ListQueryResponse<UserActivityLog>
  >({
    fetch: UserActivityLogQueries.fetch,
    defaultPageSize: 10,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => {
      const customFilters = tableFilters?.inserted_at
        ? {
            inserted_at: fromZonedTime(
              tableFilters.inserted_at,
              site?.timezone || "UTC"
            ).toISOString(),
          }
        : {};
      return [
        UserActivityLogQueries.queryKey,
        {},
        {
          ...filters,
          page,
          per_page: pageSize,
          dir: sortDirection,
          sort: sortColumn,
          ...tableFilters,
          ...customFilters,
        },
      ];
    },
    columns: compact([
      {
        name: "external_user_ids",
        header: "External Users",
        filterType: {
          type: "multiSelectField",
          getOptionValue: (option: ExternalUser) => {
            return option.id.toString();
          },
          getOptionLabel: (option: ExternalUser) => {
            return option.email;
          },
          ...externalUserSelectProps,
        },
        hidden: true,
      },
      {
        name: "user_ids",
        header: "Users",
        filterType: {
          type: "multiSelectField",
          getOptionValue: (option: User) => {
            return option.id.toString();
          },
          getOptionLabel: (option: User) => {
            return `${option.last_name}, ${option.first_name}`;
          },
          ...userSelectProps,
        },
        hidden: true,
      },
      {
        name: "actions",
        header: "Actions",
        filterType: {
          type: "multiSelectField",
          options: [
            { label: "Create", value: "create" },
            { label: "Update", value: "update" },
            { label: "Delete", value: "delete" },
          ],
        },
        hidden: true,
      },
      {
        name: "user",
        header: "User",
        render: ({ row }) => (
          <Typography numberOfLines={numberOfLines}>
            {row.user?.name || "Unknown"}
          </Typography>
        ),
      },
      {
        name: "action",
        header: "Action",
        sortable: true,
        render: ({ row }) => (
          <Typography numberOfLines={numberOfLines}>
            {humanize(row.action)}
          </Typography>
        ),
      },
      {
        name: "category",
        header: "Category",
        sortable: true,
        render: ({ row }) => (
          <Typography numberOfLines={numberOfLines}>
            {humanize(row.category)}
          </Typography>
        ),
      },
      {
        name: "details",
        header: "Details",
        sortable: true,

        render: ({ row }) => (
          <RenderDetails row={row} numberOfLines={numberOfLines} />
        ),
      },
      {
        name: "inserted_at",
        header: "Date",
        sortable: true,
        filterType: { type: "datePickerField", format: "yyyy-mm-dd" },
        render: ({ row }) => (
          <DateTimeTypography
            dateTime={row.inserted_at}
            timezone={site.timezone}
            wrap={false}
            dayFormat="MM/dd/yyyy"
            timeDisplayColor="textDisabled"
          />
        ),
      },
    ]),
  });

  return (
    <Table<UserActivityLog>
      title={"User Activity"}
      noRecordsText={`No User Activity Found`}
      onRowPress={(row) =>
        canView
          ? drawer.push(UserActivityLogQueries.queryKey, {
              initialValues: row,
              params: { site_id: site.id },
            })
          : undefined
      }
      {...tableProps}
    />
  );
};
