import { ListQueryResponse, Table, useTableQuery } from "@smartrent/ui";

import { useCallback } from "react";

import { QueryKeys } from "@/types";
import { PathActions, Paths } from "@/lib/path";
import { Door } from "@/modules/door/types";
import { DoorQueries } from "@/modules/door/queries";
import { DomLink } from "@/components/alloy-access/Link";

import { useAppDrawer } from "@/components/layout/AppDrawer";
import { useDialog } from "@/context/dialog";

import { Site } from "@/modules/site/types";

import { CreateButton, createActionsColumn } from "@/modules/base/table/utils";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { MemberQueries } from "../queries";

import { Member } from "../types";

interface DoorMemberFilters extends Partial<Door> {
  member_id: number;
}
type DoorTableFilters = TableFilters & DoorMemberFilters;
interface DoorsTableProps {
  filters: DoorTableFilters;
  member: Member;
  site: Site;
}

export const MemberDoorsTable = ({
  member,
  site,
  filters,
}: DoorsTableProps) => {
  const drawer = useAppDrawer();
  const { confirm } = useDialog();
  const [doDelete] = MemberQueries.useUnassignDoorMutation();
  const { canUpdate, canDelete } = usePermissions(DoorQueries);

  const createAction = useCallback(
    () =>
      drawer.push(QueryKeys.MemberDoors, {
        initialValues: member,
        params: { site_id: site.id },
      }),
    [drawer, member, site]
  );

  const deleteAction = useCallback(
    async ({ id }: Door) => {
      const confirmed = await confirm({
        title: "Remove access Level?",
        description:
          "Are you sure you want to remove this door from the member?",
        confirmText: "Delete",
        confirmType: "destructive",
      });

      if (confirmed) {
        await doDelete({
          member_id: member.id,
          door_id: id,
        });
      }
    },
    [confirm, doDelete, member.id]
  );

  const tableProps = useTableQuery<any, Door, ListQueryResponse<Door>>({
    fetch: DoorQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      DoorQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
        site_id: site.id,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return (
            <DomLink
              href={Paths.GetPath(QueryKeys.Doors, PathActions.View, {
                ...filters,
                ...row,
              })}
              type="body"
              color="primary"
            >
              {row.name}
            </DomLink>
          );
        },
      },
      createActionsColumn<Door>({
        canDelete,
        onDelete: deleteAction,
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Door>
      title={""}
      noRecordsText={`No Doors Found`}
      action={
        <CreateButton
          canCreate={canUpdate}
          onCreate={createAction}
          text={"Assign Doors"}
        />
      }
      {...tableProps}
    />
  );
};
