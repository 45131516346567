import {
  VStack,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerActions,
  Button,
} from "@smartrent/ui";

import {
  Form,
  FormNumberInputField,
  FormOnSubmit,
  FormSubmit,
} from "@smartrent/forms";

import * as yup from "yup";

import { useCallback } from "react";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { Door } from "../../door/types";
import { FloorQueries } from "../queries";

const validationSchema = yup.object().shape({
  number_of_floors: yup
    .number()
    .moreThan(0)
    .required()
    .label("Number of Floors"),
});

interface AddFloorFormDrawerProps {
  door_id: Door["id"];
}
export interface CreateFloorFormProps {
  number_of_floors: number;
}

export const AddFloorFormDrawer: React.FC<
  React.PropsWithChildren<AddFloorFormDrawerProps>
> = ({ door_id }) => {
  const { activeRoute, reset, pop } = useAppDrawer();

  const [addFloors] = FloorQueries.useAddFloorsMutation();

  const handleSubmit = useCallback<FormOnSubmit<CreateFloorFormProps>>(
    async (values, helpers) => {
      try {
        await addFloors({
          values: { door_id, ...values },
        });
        pop();
      } catch (err: any) {
        const [{ description, field }] = err.response.data.errors;
        helpers.setFieldError && helpers.setFieldError(field, description);
      }
    },
    [addFloors, door_id, pop]
  );
  return (
    <Form<CreateFloorFormProps>
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Drawer
        testID="add-Floor-form-drawer"
        open={!!activeRoute}
        onClose={reset}
        anchor="right"
      >
        <DrawerHeader title={"Add Floor"} />
        <DrawerContent>
          <VStack spacing={16}>
            <FormNumberInputField
              name="number_of_floors"
              label="Number of floors"
              required
            ></FormNumberInputField>
          </VStack>
        </DrawerContent>
        <DrawerActions>
          <Button variation="plain" onPress={pop} color="grayscale">
            Cancel
          </Button>
          <FormSubmit label="Save" />
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
