import { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Typography, useTheme, VStack } from "@smartrent/ui";

import { ArrowDown, CheckCircle, KabobSolid, XCircle } from "@smartrent/icons";

import { useChannel } from "@/hooks/useChannel";
import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";

import { Member } from "@/modules/member/types";

import {
  siteEncodingTopic,
  Messages,
  generalEncodingTopic,
} from "@/lib/socket";

import { Site, SystemTypes } from "@/modules/site/types";

import { BaseDrawer } from "@/modules/base/Drawer";

import { Credential } from "../types";
import { CredentialQueries } from "../queries";

interface CredentialEncodeDrawerProps {
  initialValues: {
    credential: Credential;
    member: Member;
    site: Site;
  };
  params: {
    site_id: number;
    member_id: number;
  };
}

enum EncodeState {
  waiting = "waiting",
  encoding = "encoding",
  failed = "failed",
  success = "success",
}

const ENCODE_TIMEOUT = 30000; // 30 seconds

export const CredentialEncodeDrawer: React.FC<
  React.PropsWithChildren<CredentialEncodeDrawerProps>
> = ({ initialValues, params }) => {
  const { colors } = useTheme();
  const [encodeState, setEncodeState] = useState<EncodeState>(
    EncodeState.waiting
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const siteEncodingChannel = useChannel(
    siteEncodingTopic(initialValues.site.id)
  );

  const generalEncodingChannel = useChannel(generalEncodingTopic());

  const [encodeCredential] = CredentialQueries.useEncodeCredentialMutation();

  const doEncode = useCallback(
    async (credential: Credential) => {
      try {
        await encodeCredential(credential);
      } catch (e) {
        setErrorMessage("Failed to start encode");
        setEncodeState(EncodeState.failed);
      }
    },
    [encodeCredential, setErrorMessage, setEncodeState]
  );

  useEffect(() => {
    // Only Salto encodes are handled via an API call vs sockets
    if (initialValues?.site?.system_type == SystemTypes.Salto) {
      doEncode(initialValues.credential);
      setTimeout(() => {
        setEncodeState(EncodeState.failed);
        setErrorMessage("Encoding timed out. Please try again.");
      }, ENCODE_TIMEOUT);
    }
  }, [initialValues, doEncode]);

  useSocketChannelEvent(
    siteEncodingChannel,
    Messages.encoding_updated,
    (message) => {
      if (
        message.credential_id &&
        message.credential_id == initialValues.credential.id
      ) {
        // Don't update the state if it was already successful
        if (encodeState !== EncodeState.success) {
          switch (message.status) {
            case EncodeState.success:
              setEncodeState(EncodeState.success);
              break;
            default:
              setEncodeState(message.status);
              setErrorMessage(message.message);
              break;
          }
        }
      } else if (message.credential_id != null) {
        setEncodeState(EncodeState.failed);
        setErrorMessage(
          "Incorrect Credential. Verify the number and facility code of your credential."
        );
      } else {
        setEncodeState(message.status);
        setErrorMessage(message.message);
      }
    }
  );

  useSocketChannelEvent(
    generalEncodingChannel,
    Messages.encoding_updated,
    (message) => {
      setEncodeState(message.status);
      setErrorMessage(message.message);
    }
  );

  return (
    <BaseDrawer
      title="Encode Credential"
      subtitle={`Credential #${initialValues.credential.number}`}
      contentContainerStyle={styles.root}
    >
      <View style={styles.form}>
        <VStack spacing={16} align="center">
          <Typography type="caption">
            Grab the new card or FOB you would like to add and hold it to the
            encoder. This may take up to 30 seconds.
          </Typography>
          {encodeState === EncodeState.waiting ? (
            <VStack spacing={16} align="center">
              <ArrowDown size={64} color={colors.gray900} />
              <Typography type="titleBold">Ready to Encode</Typography>
            </VStack>
          ) : null}
          {encodeState === EncodeState.encoding ? (
            <VStack spacing={16} align="center">
              <KabobSolid size={64} color={colors.gray900} />
              <Typography type="titleBold">Encoding</Typography>
            </VStack>
          ) : null}
          {encodeState === EncodeState.failed ? (
            <VStack spacing={16} align="center">
              <XCircle size={64} color={colors.error} />
              <Typography type="titleBold">Encoding Failed</Typography>
              <Typography type="body">{errorMessage}</Typography>
            </VStack>
          ) : null}
          {encodeState === EncodeState.success ? (
            <VStack spacing={16} align="center">
              <CheckCircle size={64} color={colors.success} />
              <Typography type="titleBold">Encode Complete</Typography>
            </VStack>
          ) : null}
        </VStack>
      </View>
    </BaseDrawer>
  );
};

const styles = StyleSheet.create({
  root: { justifyContent: "flex-start" },
  form: {
    margin: 20,
  },
});
