import { Calendar, InformationSolid } from "@smartrent/icons";

import Sentry from "@/lib/sentry";

import { Alert } from "@/modules/alerts/types";

interface AlertIconProps {
  alert: Alert;
}
export const AlertIcon: React.FC<AlertIconProps> = ({ alert }) => {
  switch (alert.icon) {
    case "calendar":
      return <Calendar />;
    default:
      Sentry.captureMessage(
        `Unknown icon type for alert with key: ${alert.key} Icon: ${alert.icon}. `
      );
      return <InformationSolid />;
  }
};
