import { HStack, Link, StatusDot } from "@smartrent/ui";
import { useState } from "react";

import { useParams } from "react-router-dom";

import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";
import { hubChannelTopic, Messages } from "@/lib/socket";
import { useChannel } from "@/hooks/useChannel";

import { Paths } from "@/lib/path";

import { Hub } from "../types";

interface ControllerStatusIndicatorProps {
  hub: Hub;
}

export const HubNameAndStatusIndicator = ({
  hub,
}: ControllerStatusIndicatorProps) => {
  const { site_id } = useParams<{ site_id: string }>();
  const [isOnline, setIsOnline] = useState(hub.online);
  const hubChannel = useChannel(hubChannelTopic(hub));

  useSocketChannelEvent(hubChannel, Messages.hub_status, (response) => {
    setIsOnline(response.online || false);
  });

  return (
    <HStack spacing={8} align="center">
      <StatusDot size="large" color={isOnline ? "success" : "error"} />

      <Link
        color="primary"
        href={Paths.hubViewPath(
          hub.site_id || site_id, // assumes we're always on a site page
          hub.id
        )}
      >
        {hub.name} ({hub.serial}){" "}
      </Link>
    </HStack>
  );
};
