import { View, StyleSheet } from "react-native";
import { Formik, FormikHelpers } from "formik";
import {
  Form,
  FormikSubmit,
  FormikTextareaField,
  useToast,
} from "@smartrent/ui";
import { useCallback } from "react";
import * as yup from "yup";

import { Controller } from "@/modules/controller/types";
import { useChannel } from "@/hooks/useChannel";
import { controllerChannelTopic, Messages } from "@/lib/socket";

const validationSchema = yup.object().shape({
  command: yup.string().nullable().required().label("Command"),
});

interface SHIPCommandPayload {
  command: string;
}

export const SHIPDebugPanel = ({ controller }: { controller: Controller }) => {
  const controllerChannel = useChannel(controllerChannelTopic(controller));
  const setToast = useToast();

  const initialCommand = `<?xml version='1.0' encoding='UTF-8'?>
    <RequestCall>
      <RequestName></RequestName>
      <Params>
      </Params>
    </RequestCall>
  `;

  const submitForm = useCallback(
    (
      values: SHIPCommandPayload,
      formikHelpers: FormikHelpers<SHIPCommandPayload>
    ) => {
      console.log(values);
      if (controllerChannel?.state !== "joined") {
        setToast({
          message: "Socket disconnected, refresh the page and try again.",
          status: "error",
        });
        formikHelpers.setSubmitting(false);
        return;
      }

      controllerChannel.push(Messages.debug_send_request, values);
      formikHelpers.setSubmitting(false);
    },
    [controllerChannel, setToast]
  );

  return (
    <View style={styles.root}>
      <View>
        <Formik<SHIPCommandPayload>
          initialValues={{ command: initialCommand }}
          onSubmit={submitForm}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {() => {
            return (
              <Form>
                <View style={styles.formContainer}>
                  <View>
                    <FormikTextareaField
                      name="command"
                      label="Command"
                      textInputProps={{
                        style: { height: 180 },
                      }}
                    />
                  </View>
                  <View style={styles.formHSubmit}>
                    <FormikSubmit size="large" />
                  </View>
                </View>
              </Form>
            );
          }}
        </Formik>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "column",
  },
  formContainer: {
    padding: 8,
  },
  formHSubmit: {
    padding: 4,
  },
});
