import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { LoadingCard } from "@smartrent/ui";
import { useMemo, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";

import {
  Calendar,
  CalendarDisabled,
  ClipboardTask,
  CloudUpload,
  Cog,
  ComputerChip,
  CreditCard,
  DoorOpen,
  Graph,
  Hub,
  Person,
  PinPad,
  Upload,
  Users,
  Wrench,
} from "@smartrent/icons";

import { SiteQueries } from "@/modules/site/queries";
import { LinkData } from "@/components/alloy-access/NavDrawer";
import { PageCtx } from "@/context/PageContext";
import { NavigationLinksCtx } from "@/context/NavigationLinksContext";
import { Paths } from "@/lib/path";
import { ErrorPage } from "@/components/ErrorPage";
import { User } from "@/modules/user/types";
import squares from "@/assets/images/squares.svg";
import { SystemHealthQueries } from "@/modules/system-health/queries";

import { QueryKeys } from "@/types";

import { SiteConfiguration } from "@/types/sites";

import { PolicyContext } from "@/context/PolicyContext";

import { MembersRouter } from "./members";
import { SiteSettingsRouter } from "./settings";
import { SystemHealthRouter } from "./system-health";
import { ApiClientRouter } from "./api-applications";
import { AccessLevelsRouter } from "./access-levels";
import { ControllersRouter } from "./controllers";
import { EncodersRouter } from "./encoders";
import { CardsRouter } from "./cards";
import { DoorsRouter } from "./doors";
import { ImportsRouter } from "./imports";
import { EventsRouter } from "./events";
import { HubsRouter } from "./hubs";
import { SchedulesRouter } from "./schedules";
import { HolidaySchedulesRouter } from "./holiday-schedules";
import { OperatorsRouter } from "./operators";
import { UserActivityLogsRouter } from "./user-activity-logs";

interface SiteRouterProps {
  user: User;
}

export const SiteRouter = ({ user }: SiteRouterProps) => {
  const {
    path,
    url,
    params: { site_id },
  } = useRouteMatch<{ site_id: string }>();
  const { data: site, isLoading } = SiteQueries.useQuery({
    id: Number(site_id),
  });
  const { addBreadcrumb, breadCrumbs, setSystemIssues, setSite } =
    useContext(PageCtx);
  const { setNavigationLinks } = useContext(NavigationLinksCtx);
  const permissions = useContext(PolicyContext);

  useEffect(() => {
    if (!site) return;
    addBreadcrumb({
      label: `${site.name}`,
      to: Paths.siteViewPath(site.id),
    });
  }, [site, breadCrumbs, addBreadcrumb]);

  useEffect(() => {
    if (!site) {
      setSite(null);
      setSystemIssues(null);
    } else {
      setSite(site);
      SystemHealthQueries.getIssues(site.id).then((res) => {
        setSystemIssues(res.data.records);
      });
    }

    return function cleanup() {
      setSite(null);
    };
  }, [site, setSite, setSystemIssues]);

  const navLinks = useMemo(() => {
    if (!site || !site.system_type || !SiteConfiguration[site.system_type])
      return [];

    const links = SiteConfiguration[site.system_type].navigation;

    const accessLinks: LinkData[] = [];
    const activityLinks: LinkData[] = [];
    const overviewLinks: LinkData[] = [];
    const configurationLinks: LinkData[] = [];

    const result = [];

    links.forEach(
      (link) => {
        if (!permissions || !permissions[link].list) return;

        switch (link) {
          case QueryKeys.Members:
            accessLinks.push({
              url: `/sites/${site.id}/members`,
              label: "Members",
              icon: Person,
            });
            break;
          case QueryKeys.Operators:
            accessLinks.push({
              url: `/sites/${site.id}/operators`,
              label: "Operators",
              icon: Wrench,
            });
            break;
          case QueryKeys.Events:
            activityLinks.push({
              url: `/sites/${site.id}/events`,
              label: "Events",
              icon: ClipboardTask,
            });
            break;
          case QueryKeys.UserActivityLogs:
            activityLinks.push({
              url: `/sites/${site.id}/user-activity-logs`,
              label: "User Activity",
              icon: Users,
            });
            break;
          case QueryKeys.SystemHealth:
            overviewLinks.push({
              url: `/sites/${site.id}/system-health`,
              label: "Health Dashboard",
              icon: Graph,
            });
            break;
          case QueryKeys.Hubs:
            configurationLinks.push({
              url: `/sites/${site.id}/hubs`,
              label: "Cloud Bridge",
              icon: Hub,
            });
            break;
          case QueryKeys.Schedules:
            configurationLinks.push({
              url: `/sites/${site.id}/schedules`,
              label: "Schedules",
              icon: Calendar,
            });
            break;
          case QueryKeys.HolidayScheduleGroups:
            configurationLinks.push({
              url: `/sites/${site.id}/holiday-schedules`,
              label: "Exceptions",
              icon: CalendarDisabled,
            });
            break;
          case QueryKeys.AccessLevels:
            configurationLinks.push({
              url: `/sites/${site.id}/access-levels`,
              label: "Access Levels",
              icon: Users,
            });
            break;
          case QueryKeys.Doors:
            configurationLinks.push({
              url: `/sites/${site.id}/doors`,
              label: "Doors",
              icon: DoorOpen,
            });
            break;
          case QueryKeys.Controllers:
            configurationLinks.push({
              url: `/sites/${site.id}/controllers`,
              label: "Controllers",
              icon: ComputerChip,
            });
            break;
          case QueryKeys.CardFormats:
            configurationLinks.push({
              url: `/sites/${site.id}/cards`,
              label: "Card Bank",
              icon: CreditCard,
            });
            break;
          case QueryKeys.SiteImports:
            configurationLinks.push({
              url: `/sites/${site.id}/imports`,
              label: "Import/Export",
              icon: Upload,
            });
            break;
          case QueryKeys.ApiApplications:
            configurationLinks.push({
              url: `/sites/${site.id}/api-clients`,
              label: "API Clients",
              icon: CloudUpload,
            });
            break;
          case QueryKeys.Encoders:
            configurationLinks.push({
              url: `/sites/${site.id}/encoders`,
              label: "Encoders",
              icon: PinPad,
            });
            break;
        }
      },
      [site]
    );

    if (accessLinks.length > 0) {
      result.push({
        label: "Access",
        icon: squares,
        items: accessLinks,
      });
    }

    if (activityLinks.length > 0) {
      result.push({
        label: "Activity",
        icon: squares,
        items: activityLinks,
      });
    }

    if (overviewLinks.length > 0) {
      result.push({
        label: "System Overview",
        icon: squares,
        items: overviewLinks,
      });
    }

    if (configurationLinks.length > 0) {
      result.push({
        label: "Configuration",
        icon: squares,
        items: configurationLinks.concat({
          url: `/sites/${site.id}/settings`,
          label: "Site Settings",
          icon: Cog,
        }),
      });
    }

    return result;
  }, [permissions, site]);

  useEffect(() => {
    setNavigationLinks(navLinks);
  }, [setNavigationLinks, navLinks]);

  if (isLoading) return <LoadingCard />;
  if (!site || !SiteConfiguration[site.system_type])
    return <ErrorPage title={"Failed To Load Site"} />;

  return (
    <View style={styles.root}>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${url}/members`} />
        </Route>
        <Route path={`${path}/access-levels`}>
          <AccessLevelsRouter site={site} />
        </Route>
        <Route path={`${path}/api-clients`}>
          <ApiClientRouter site={site} />
        </Route>
        <Route path={`${path}/cards`}>
          <CardsRouter site={site} />
        </Route>
        <Route path={`${path}/controllers`}>
          <ControllersRouter site={site} />
        </Route>
        <Route path={`${path}/doors`}>
          <DoorsRouter site={site} />
        </Route>
        <Route path={`${path}/imports`}>
          <ImportsRouter site={site} />
        </Route>
        <Route path={`${path}/events`}>
          <EventsRouter site={site} />
        </Route>
        <Route path={`${path}/user-activity-logs`}>
          <UserActivityLogsRouter site={site} />
        </Route>
        <Route path={`${path}/hubs`}>
          <HubsRouter site={site} />
        </Route>
        <Route path={`${path}/members`}>
          <MembersRouter site={site} />
        </Route>
        <Route path={`${path}/operators`}>
          <OperatorsRouter site={site} />
        </Route>
        <Route path={`${path}/schedules`}>
          <SchedulesRouter site={site} />
        </Route>
        <Route path={`${path}/exception-groups`}>
          <HolidaySchedulesRouter site={site} />
        </Route>
        <Route path={`${path}/settings`}>
          <SiteSettingsRouter site={site} />
        </Route>
        <Route path={`${path}/system-health`}>
          <SystemHealthRouter site={site} />
        </Route>
        <Route path={`${path}/encoders`}>
          <EncodersRouter site={site} />
        </Route>
      </Switch>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: 16,
  },
});
