import { useCallback } from "react";
import { View, StyleSheet } from "react-native";
import { Formik, FormikHelpers } from "formik";

import {
  DrawerActions,
  DrawerContent,
  Button,
  FormikSubmit,
} from "@smartrent/ui";

import { useHistory } from "react-router-dom";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { BaseRecord } from "./types";
import { BaseQueryClient } from "./queries";

export interface FormProps<T extends BaseRecord> {
  initialValues: Partial<T>;
  params: any;
}

interface BaseFormProps<T extends BaseRecord> {
  afterSubmit?: () => void;
  children: any;
  initialValues: Partial<T>;
  validationSchema: any;
  params?: any;
  QueryClient: BaseQueryClient<T>;
  saveText?: string;
}

export function BaseForm<T extends BaseRecord>({
  afterSubmit,
  initialValues,
  validationSchema,
  children,
  QueryClient,
  saveText,
  params,
}: BaseFormProps<T>) {
  const drawer = useAppDrawer();
  const [create] = QueryClient.useCreateMutation();
  const [update] = QueryClient.useUpdateMutation();
  const history = useHistory();

  const handleBack = useCallback(() => {
    drawer.pop();
  }, [drawer]);

  const handleSubmit = useCallback<
    (values: Partial<T>, formikHelpers: FormikHelpers<Partial<T>>) => void
  >(
    async (values, { setFieldError }) => {
      try {
        if (values?.id) {
          await update({
            id: Number(values.id),
            values: values as T,
          });
        } else {
          const created = await create({ values });

          if (QueryClient.createRedirectPath) {
            const redirectPath = QueryClient.createRedirectPath(
              created,
              params
            );
            if (redirectPath) {
              history.push(redirectPath);
            }
          }
        }
        handleBack();
        if (afterSubmit) {
          await afterSubmit();
        }
      } catch (err: any) {
        const [{ description, field }] = err.response.data.errors;
        setFieldError && setFieldError(field, description);
      }
    },
    [afterSubmit, handleBack, update, create, QueryClient, history, params]
  );

  return (
    <View style={styles.root}>
      <Formik<Partial<T>>
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <>
            <DrawerContent>{children(formikProps)}</DrawerContent>
            <View style={styles.actionsWrapper}>
              <DrawerActions>
                <View style={styles.actionContainer}>
                  {drawer.activeRoute && (
                    <Button
                      onPress={handleBack}
                      variation="plain"
                      style={styles.button}
                      color="grayscale"
                    >
                      Cancel
                    </Button>
                  )}
                  <FormikSubmit
                    label={saveText || "Save"}
                    submittingLabel={saveText || "Saving..."}
                    style={styles.button}
                  />
                </View>
              </DrawerActions>
            </View>
          </>
        )}
      </Formik>
    </View>
  );
}

// TODO: Make this not suck
const styles = StyleSheet.create({
  root: { flexGrow: 1 },
  actionsWrapper: { flexGrow: 0 },
  actionContainer: {
    width: "100%",
    flexDirection: "row",
  },
  button: {
    flexGrow: 1,
    margin: 4,
  },
});
