import { useCallback } from "react";

import * as yup from "yup";

import { AxiosError, isAxiosError } from "axios";

import {
  Button,
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  VStack,
} from "@smartrent/ui";
import {
  Form,
  FormOnSubmit,
  FormSubmit,
  FormTextInputField,
} from "@smartrent/forms";

import { SolicitApiErrorResponse } from "@/types/solicit";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { SiteNotificationRecipient } from "./types";
import { SiteNotificationRecipientQueries } from "./queries";

const createValidationSchema = yup.object().shape({
  email: yup.string().required().label("Email"),
});

interface SiteNotificationRecipientDrawerProps {
  site_id: number;
}
interface SiteNotificationRecipientFormValues
  extends Partial<SiteNotificationRecipient> {}

export const SiteNotificationRecipientDrawer: React.FC<
  React.PropsWithChildren<SiteNotificationRecipientDrawerProps>
> = ({ site_id }) => {
  const { activeRoute, reset, pop } = useAppDrawer();

  const [create] = SiteNotificationRecipientQueries.useCreateMutation();

  const handleSubmit = useCallback<
    FormOnSubmit<SiteNotificationRecipientFormValues>
  >(
    async (values, helpers) => {
      try {
        await create({ values });
        pop();
      } catch (error) {
        // try to display any api errors in the form
        if (isAxiosError(error)) {
          const response = (error as AxiosError)
            .response as SolicitApiErrorResponse<SiteNotificationRecipient>;
          if (response?.data?.errors?.length) {
            response.data.errors.forEach((error) => {
              if (typeof error !== "string") {
                helpers.setFieldError(error.field, error.description);
              }
            });
          }
        } else {
          // close drawer, since there's no form errors to display
          pop();
        }
      }
    },
    [create, pop]
  );

  return (
    <Form<SiteNotificationRecipientFormValues>
      initialValues={{
        // Set to undefined for yup validation error assignment.
        email: undefined,
        site_id: site_id,
        type: "site_staff",
      }}
      validationSchema={createValidationSchema}
      onSubmit={handleSubmit}
    >
      <Drawer
        testID="site-notification-recipient-drawer-add"
        open={!!activeRoute}
        onClose={reset}
        anchor="right"
      >
        <DrawerHeader title="Add Notification Recipient" />
        <DrawerContent>
          <VStack spacing={8}>
            <FormTextInputField name="email" label="Email" required />
          </VStack>
        </DrawerContent>

        <DrawerActions>
          <Button variation="outlined" onPress={pop}>
            Cancel
          </Button>
          <FormSubmit label="Save" />
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
