import { useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { View, StyleSheet } from "react-native";

import {
  LoadingCard,
  useToast,
  Button,
  HStack,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitle,
  VStack,
  Typography,
} from "@smartrent/ui";

import { AuthQueries } from "@/modules/auth/queries";

export function LinkAccountToUmbrellaUser() {
  const { id_token }: Record<string, any> = useParams();
  const setToast = useToast();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [linkAccount] = AuthQueries.useLinkAccountMutation();

  const linkAccountCallback = useCallback(() => {
    setLoading(true);
    linkAccount({ id_token })
      .then(() => {
        setToast({
          status: "success",
          message: "Your account was linked successfully.",
        });
      })
      .catch(() => {
        setToast({
          status: "error",
          message: "There was an error linking your account.",
        });
      })
      .finally(() => {
        history.push("/");
      });
  }, [linkAccount, id_token, history, setToast]);

  const navigateAwayCallback = useCallback(() => {
    history.push("/");
  }, [history]);

  if (loading) {
    return <LoadingCard />;
  }

  return (
    <View style={styles.container}>
      <Panel>
        <PanelHeader>
          <PanelHeaderTitle title="SmartRent Launch" />
        </PanelHeader>
        <PanelBody>
          <VStack spacing={8}>
            <Typography>You've logged in via our Launch system.</Typography>
            <Typography>
              Launch allows you to link your accounts so that you can be logged
              in automatically when you navigate to this application from
              another SmartRent application.
            </Typography>
            <View style={styles.mt16}>
              <HStack spacing={8}>
                <Button
                  variation="plain"
                  color="grayscale"
                  onPress={navigateAwayCallback}
                >
                  Cancel
                </Button>
                <Button variation="default" onPress={linkAccountCallback}>
                  Link Account
                </Button>
              </HStack>
            </View>
          </VStack>
        </PanelBody>
      </Panel>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 16,
    alignItems: "center",
  },
  mt16: {
    marginTop: 16,
    alignItems: "flex-end",
  },
});
