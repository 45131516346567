import { StatusDot, Typography, TypographyProps } from "@smartrent/ui";

import { View, ViewStyle, StyleSheet } from "react-native";

import { useQueryClient } from "@tanstack/react-query";

import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";
import { Panel } from "@/modules/panel/types";
import { panelChannelTopic, Messages } from "@/lib/socket";
import { useChannel } from "@/hooks/useChannel";
import { QueryKeys } from "@/types";

interface WebsocketViewPanelStatusProps {
  panel: Panel;
  style?: ViewStyle;
  typographyProps?: TypographyProps;
}

export const WebsocketViewPanelStatus: React.FC<
  React.PropsWithChildren<WebsocketViewPanelStatusProps>
> = ({ panel, style, typographyProps }) => {
  const queryClient = useQueryClient();
  const panelChannel = useChannel(panelChannelTopic(panel));

  // invalidate react query cache when panel status changes
  useSocketChannelEvent(panelChannel, Messages.panel_status, (response) => {
    queryClient.invalidateQueries([QueryKeys.Panels]);
  });

  // Panel status: yellow (pending) = null, green (online) = true, red (offline) = false
  const color =
    panel?.online === null ? "warning" : panel?.online ? "success" : "error";

  return (
    <View style={[styles.root, style]}>
      <View style={styles.item}>
        <StatusDot size="large" color={color} variation={"solid"} />
      </View>

      <Typography {...typographyProps}>{panel.name}</Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexShrink: 1,
    flexDirection: "row",
    flexAlign: "center",
    alignItems: "center",
  },
  item: {
    marginRight: 12,
  },
});
