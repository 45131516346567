import {
  Panel,
  PanelHeader,
  TabContent,
  TabProvider,
  TabsNavigation,
  useTheme,
} from "@smartrent/ui";

import { StyleSheet } from "react-native";

import { useCallback } from "react";

import { Site } from "@/modules/site/types";

import { Member } from "@/modules/member/types";

import { MemberAccessType } from "@/modules/credential/types";
import { SiteConfiguration } from "@/types/sites";

import { useResetPageToOne } from "@/hooks/useResetPageToOne";

import { AccessLevelsDisplay } from "./action-panels/AccessLevelsDisplay";
import { CredentialsDisplay } from "./action-panels/CredentialsDisplay";
import { DoorsDisplay } from "./action-panels/DoorsDisplay";

interface MemberActionPanelProps {
  member: Member;
  site: Site;
}

export const MemberActionPanel = ({ member, site }: MemberActionPanelProps) => {
  const { colors } = useTheme();
  const resetPageToOne = useResetPageToOne();

  const tabs = useCallback(() => {
    if (!site || !site.system_type) {
      return [];
    }

    return SiteConfiguration[site.system_type].memberAccessTypes.map((type) => {
      switch (type) {
        case MemberAccessType.Credentials:
          return {
            id: "credentials",
            label: "Credentials",
            onPress: resetPageToOne,
          };
        case MemberAccessType.AccessLevels:
          return {
            id: "access_levels",
            label: "Access Levels",
            onPress: resetPageToOne,
          };
        case MemberAccessType.Doors:
          return {
            id: "doors",
            label: "Doors",
            onPress: resetPageToOne,
          };
      }
    });
  }, [resetPageToOne, site]);

  const body = useCallback(() => {
    return (
      <>
        {SiteConfiguration[site.system_type].memberAccessTypes.includes(
          MemberAccessType.Credentials
        ) ? (
          <TabContent id="credentials">
            <CredentialsDisplay member={member} site={site} />
          </TabContent>
        ) : null}

        {SiteConfiguration[site.system_type].memberAccessTypes.includes(
          MemberAccessType.AccessLevels
        ) ? (
          <TabContent id="access_levels">
            <AccessLevelsDisplay member={member} site={site} />
          </TabContent>
        ) : null}

        {SiteConfiguration[site.system_type].memberAccessTypes.includes(
          MemberAccessType.Doors
        ) ? (
          <TabContent id="doors">
            <DoorsDisplay member={member} site={site} />
          </TabContent>
        ) : null}
      </>
    );
  }, [member, site]);

  return (
    <TabProvider>
      <Panel>
        <PanelHeader
          style={[styles.header, { backgroundColor: colors.background }]}
        >
          <TabsNavigation initialActiveTabId={tabs()[0].id} items={tabs()} />
        </PanelHeader>
        {body()}
      </Panel>
    </TabProvider>
  );
};

const styles = StyleSheet.create({
  header: {
    padding: 0,
  },
});
