import {
  createContext,
  useContext,
  useEffect,
  memo,
  useRef,
  useState,
} from "react";
import { isEqual } from "lodash-es";

import { UserQueries } from "@/modules/user/queries";
import { BaseQueryClient } from "@/modules/base/queries";
import { PermissionsList, User } from "@/modules/user/types";

import { QueryKeys } from "@/types";

import { AuthContext } from "./Auth";

const emptyPermissionSet = () => ({
  create: false,
  show: false,
  list: false,
  update: false,
  delete: false,
});

export const EMPTY_PERMISSIONS = {
  [QueryKeys.Doors]: emptyPermissionSet(),
  [QueryKeys.Elevators]: emptyPermissionSet(),
  [QueryKeys.ElevatorAccessLevels]: emptyPermissionSet(),
  [QueryKeys.Members]: emptyPermissionSet(),
  [QueryKeys.Credentials]: emptyPermissionSet(),
  [QueryKeys.CascadingOptions]: emptyPermissionSet(),
  [QueryKeys.AccessLevels]: emptyPermissionSet(),
  [QueryKeys.AccessLevelSchedules]: emptyPermissionSet(),
  [QueryKeys.MemberAccessLevels]: emptyPermissionSet(),
  [QueryKeys.MemberDoors]: emptyPermissionSet(),
  [QueryKeys.Users]: emptyPermissionSet(),
  [QueryKeys.ExternalUsers]: emptyPermissionSet(),
  [QueryKeys.Hubs]: emptyPermissionSet(),
  [QueryKeys.Controllers]: emptyPermissionSet(),
  [QueryKeys.FeatureFlags]: emptyPermissionSet(),
  [QueryKeys.Firmware]: emptyPermissionSet(),
  [QueryKeys.CardFormats]: emptyPermissionSet(),
  [QueryKeys.Sites]: emptyPermissionSet(),
  [QueryKeys.Organizations]: emptyPermissionSet(),
  [QueryKeys.ApiApplications]: emptyPermissionSet(),
  [QueryKeys.Schedules]: emptyPermissionSet(),
  [QueryKeys.ScheduleInterval]: emptyPermissionSet(),
  [QueryKeys.SiteImports]: emptyPermissionSet(),
  [QueryKeys.Operators]: emptyPermissionSet(),
  [QueryKeys.Events]: emptyPermissionSet(),
  [QueryKeys.UserActivityLogs]: emptyPermissionSet(),
  [QueryKeys.Panels]: emptyPermissionSet(),
  [QueryKeys.Floors]: emptyPermissionSet(),
  [QueryKeys.Readers]: emptyPermissionSet(),
  [QueryKeys.ReadersWarbler]: emptyPermissionSet(),
  [QueryKeys.Inputs]: emptyPermissionSet(),
  [QueryKeys.Outputs]: emptyPermissionSet(),
  [QueryKeys.CallRooms]: emptyPermissionSet(),
  [QueryKeys.SipAccounts]: emptyPermissionSet(),
  [QueryKeys.SystemHealth]: emptyPermissionSet(),
  [QueryKeys.Encoders]: emptyPermissionSet(),
  [QueryKeys.EncodeCredential]: emptyPermissionSet(),
  [QueryKeys.HolidaySchedules]: emptyPermissionSet(),
  [QueryKeys.HolidayScheduleGroups]: emptyPermissionSet(),
  [QueryKeys.ScheduleHolidayGroups]: emptyPermissionSet(),
  [QueryKeys.SiteFirmware]: emptyPermissionSet(),
  [QueryKeys.SiteNotificationRecipients]: emptyPermissionSet(),
  [QueryKeys.SiteAlerts]: emptyPermissionSet(),
};

export const PolicyContext = createContext<PermissionsList>(EMPTY_PERMISSIONS);

const PermissionsProvider = memo(
  ({ children, user }: { children: React.ReactNode; user: User | null }) => {
    const { data: permissions, refetch } =
      UserQueries.usePermissionsQuery(user);

    useEffect(() => {
      refetch();
    }, [refetch, user]);

    return (
      <PolicyContext.Provider value={permissions as PermissionsList}>
        {children}
      </PolicyContext.Provider>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.user, nextProps.user)
);

export const PermissionsProviderContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useContext(AuthContext);

  return <PermissionsProvider user={user}>{children}</PermissionsProvider>;
};

export function usePermissions<T>(
  queryClient: Pick<BaseQueryClient<T>, "queryKey">
) {
  const isFirstRender = useRef<boolean>(true);

  const permissions = useContext(PolicyContext);

  const [canCreate, setCanCreate] = useState(
    permissions && permissions[queryClient.queryKey]?.create
  );
  const [canDelete, setCanDelete] = useState(
    permissions && permissions[queryClient.queryKey]?.delete
  );
  const [canList, setCanList] = useState(
    permissions && permissions[queryClient.queryKey]?.list
  );
  const [canUpdate, setCanUpdate] = useState(
    permissions && permissions[queryClient.queryKey]?.update
  );
  const [canView, setCanView] = useState(
    permissions && permissions[queryClient.queryKey]?.show
  );

  useEffect(() => {
    // Bail early on the first render since we know permissions won't be set yet and nothing will change
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setCanCreate(permissions && permissions[queryClient.queryKey]?.create);
    setCanDelete(permissions && permissions[queryClient.queryKey]?.delete);
    setCanList(permissions && permissions[queryClient.queryKey]?.list);
    setCanUpdate(permissions && permissions[queryClient.queryKey]?.update);
    setCanView(permissions && permissions[queryClient.queryKey]?.show);
  }, [permissions, queryClient.queryKey]);

  return {
    canCreate,
    canDelete,
    canList,
    canUpdate,
    canView,
  };
}
