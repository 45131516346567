import { User } from "@sentry/react";

import { humanize } from "@/lib/helpers";

import { BaseRecord } from "../base/types";
import { Controller } from "../controller/types";
import { Door } from "../door/types";
import { Hub } from "../hub/types";
import { Member } from "../member/types";
import { PassUser } from "../pass-user/types";
import { Credential } from "../credential/types";

export interface Event extends BaseRecord {
  site_id: number;
  door_id?: number;
  type: EventTypes;
  details?: any;
  happened_at?: string;
  inserted_at?: string;
  updated_at?: string;
  code?: EventCodes;
  credential_id?: number;
  door_name?: string;
  first_name?: string;
  last_name?: string;
  credential_type?: string;
  pass_id?: number;
  device_id?: number;
  member_id?: number;
  ap_user_id?: number;
  user_id?: number;
  hub_id?: number;
  controller_id?: number;
  door?: Door;
  credential?: Credential;
  device?: Record<string, any>;
  member?: Member;
  ap_user?: PassUser;
  user?: User;
  hub?: Hub;
  controller?: Controller;
}

export enum EventTypes {
  credential = "credential",
  door = "door",
  rex = "rex",
  mode_change = "mode_change",
  change_of_state_elevator = "change_of_state_elevator",
  change_of_state_elevator_access = "change_of_state_elevator_access",
  schlage = "Schlage",
  door_forced_open = "door_forced_open",
  door_held_open = "door_held_open",
  door_secured = "door_secured",
}

export enum EventCodes {
  // MPL
  secure = "secure",
  public = "public",
  denied_invalid_card_format = "denied_invalid_card_format",
  rejected_invalid_facility_code = "rejected_invalid_facility_code",
  granted_full_test_used = "granted_full_test_used",
  card_only = "card_only",
  jammed = "jammed",
  elevator_access = "elevator_access",
  exit_cycle_door_use_not_verified = "exit_cycle_door_use_not_verified",
  rejected_incomplete_card_pin_sequence = "rejected_incomplete_card_pin_sequence",
  rejected_after_expiration_date = "rejected_after_expiration_date",
  accepted_unlocked = "accepted_unlocked",
  locked = "locked",
  rejected_locked = "rejected_locked",
  unlocked = "unlocked",
  pin_only = "pin_only",
  unlocking = "unlocking",
  rejected_before_activation_date = "rejected_before_activation_date",
  denied_never_allowed_at_this_reader = "denied_never_allowed_at_this_reader",
  reset = "reset",
  unknown = "unknown",
  granted_full_test_not_used = "granted_full_test_not_used",
  locking = "locking",
  rejected_invalid_time = "rejected_invalid_time",
  disabled = "disabled",
  none = "none",
  rejected_card_not_on_file = "rejected_card_not_on_file",
  card_or_pin = "card_or_pin",
  rejected_invalid_pin = "rejected_invalid_pin",
  facility_code_only = "facility_code_only",

  // https://github.com/smartrent/cloud_bridge/blob/main/janus/README.md#cards
  rejected_no_door = "rejected_no_door",
  rejected_door_disabled = "rejected_door_disabled",
  rejected_door_locked = "rejected_door_locked",
  // accepted_unlocked = "accepted_unlocked",
  unsupported_card_format = "unsupported_card_format",
  // denied_invalid_card_format = "denied_invalid_card_format",
  rejected_not_on_file = "rejected_not_on_file",
  rejected_before_time = "rejected_before_time",
  rejected_after_time = "rejected_after_time",
  // rejected_invalid_time = "rejected_invalid_time",
  granted = "granted",
  // rejected_invalid_pin = "rejected_invalid_pin",
  unlock = "unlock",
  lock = "lock",
}

export const EventCodeOptions = [
  {
    value: EventCodes.denied_invalid_card_format,
    label: humanize(EventCodes.denied_invalid_card_format),
  },
  {
    value: EventCodes.rejected_invalid_facility_code,
    label: humanize(EventCodes.rejected_invalid_facility_code),
  },
  {
    value: EventCodes.granted_full_test_used,
    label: humanize(EventCodes.granted_full_test_used),
  },
  { value: EventCodes.card_only, label: humanize(EventCodes.card_only) },
  { value: EventCodes.jammed, label: humanize(EventCodes.jammed) },
  {
    value: EventCodes.elevator_access,
    label: humanize(EventCodes.elevator_access),
  },
  {
    value: EventCodes.exit_cycle_door_use_not_verified,
    label: humanize(EventCodes.exit_cycle_door_use_not_verified),
  },
  {
    value: EventCodes.rejected_incomplete_card_pin_sequence,
    label: humanize(EventCodes.rejected_incomplete_card_pin_sequence),
  },
  {
    value: EventCodes.rejected_after_expiration_date,
    label: humanize(EventCodes.rejected_after_expiration_date),
  },
  {
    value: EventCodes.accepted_unlocked,
    label: humanize(EventCodes.accepted_unlocked),
  },
  { value: EventCodes.locked, label: humanize(EventCodes.locked) },
  {
    value: EventCodes.rejected_locked,
    label: humanize(EventCodes.rejected_locked),
  },
  { value: EventCodes.unlocked, label: humanize(EventCodes.unlocked) },
  { value: EventCodes.pin_only, label: humanize(EventCodes.pin_only) },
  { value: EventCodes.unlocking, label: humanize(EventCodes.unlocking) },
  {
    value: EventCodes.rejected_before_activation_date,
    label: humanize(EventCodes.rejected_before_activation_date),
  },
  {
    value: EventCodes.denied_never_allowed_at_this_reader,
    label: humanize(EventCodes.denied_never_allowed_at_this_reader),
  },
  { value: EventCodes.reset, label: humanize(EventCodes.reset) },
  { value: EventCodes.unknown, label: humanize(EventCodes.unknown) },
  {
    value: EventCodes.granted_full_test_not_used,
    label: humanize(EventCodes.granted_full_test_not_used),
  },
  { value: EventCodes.locking, label: humanize(EventCodes.locking) },
  {
    value: EventCodes.rejected_invalid_time,
    label: humanize(EventCodes.rejected_invalid_time),
  },
  { value: EventCodes.disabled, label: humanize(EventCodes.disabled) },
  { value: EventCodes.none, label: humanize(EventCodes.none) },
  {
    value: EventCodes.rejected_card_not_on_file,
    label: humanize(EventCodes.rejected_card_not_on_file),
  },
  { value: EventCodes.card_or_pin, label: humanize(EventCodes.card_or_pin) },
  {
    value: EventCodes.rejected_invalid_pin,
    label: humanize(EventCodes.rejected_invalid_pin),
  },
  {
    value: EventCodes.facility_code_only,
    label: humanize(EventCodes.facility_code_only),
  },
];

export const EventTypeOptions = [
  { value: EventTypes.credential, label: humanize(EventTypes.credential) },
  { value: EventTypes.door, label: humanize(EventTypes.door) },
  {
    value: EventTypes.door_held_open,
    label: humanize(EventTypes.door_held_open),
  },
  {
    value: EventTypes.door_forced_open,
    label: humanize(EventTypes.door_forced_open),
  },
  { value: EventTypes.rex, label: humanize(EventTypes.rex) },
  { value: EventTypes.mode_change, label: humanize(EventTypes.mode_change) },
];
