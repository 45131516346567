import {
  createContext,
  ReactNode,
  // useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Socket as PhxSocket } from "phoenix";

// import { ScrollView, StyleSheet, View } from "react-native";
// import { HStack, Typography, useTheme } from "@smartrent/ui";

import { AuthContext } from "./Auth";

type SocketChannelMap = Map<string, number>;

const retryDelay = (attemptNumber: number) =>
  attemptNumber * 1000 * attemptNumber;

const onError = (
  e: Event | string | number,
  _transport: new (endpoint: string) => object,
  _establishedConnections: number
) => {
  console.warn("Error with socket", e);
};

const SocketContext = createContext<{
  channelMap: SocketChannelMap;
  socket?: AASocket;
  isConnected: boolean;
}>(undefined!);

class AASocket extends PhxSocket {
  setParams(params: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- I swear this works.
    // @ts-ignore This does exist, it's just deprecated.
    // We will need to watch this if we upgrade to a newer version of the phoenix socket.
    this.params = () => params || {};
  }
}

const useSocket = (): {
  socket?: AASocket;
  isConnected: boolean;
} => {
  const { accessToken } = useContext(AuthContext);

  const socket = useRef<AASocket>();
  const [isConnected, setIsConnected] = useState(false);

  const onOpen = () => {
    setIsConnected(true);
  };

  const onClose = () => {
    setIsConnected(false);
  };

  const openRef = useRef<string>("");
  const closeRef = useRef<string>("");
  const errorRef = useRef<string>("");

  // 1. tearDown for socket, when hook unmounts
  // NOTE: (kjb) This literally only happens in dev when you make changes
  // to this file. Otherwise the whole app loads the socket once and never
  // unmounts it.
  useEffect(() => {
    return () => {
      socket.current?.disconnect(() => {
        socket.current?.off([
          openRef.current,
          closeRef.current,
          errorRef.current,
        ]);
      });
    };
  }, []);

  // 2. check for changes in token, and connect/disconnect socket accordingly
  useEffect(() => {
    // 1. do nothing, no token
    if (!accessToken && !socket.current?.isConnected()) {
      return;
    }

    // 2. pre-existing socket exists, no token, disconnect immediately
    if (!accessToken && socket.current?.isConnected()) {
      socket.current?.disconnect(() => {
        socket.current?.off([
          openRef.current,
          closeRef.current,
          errorRef.current,
        ]);
      });
      return;
    }

    // 3. new token, pre-existing socket exists, update guardian_token on existing socket
    if (accessToken && socket.current?.isConnected()) {
      socket.current?.setParams({
        guardian_token: accessToken,
      });
      return;
    }

    // 4. no socket exists, new token found, create new socket
    socket.current = new AASocket(process.env.SOCKET_BASE_URL as string, {
      params: {
        // The token is set here for the first setup of the socket.
        // The token will automatically update the variable, but it will last until it attempts to reconnect.
        guardian_token: accessToken,
      },
      reconnectAfterMs: retryDelay,
    });

    // this overwrites the old refs we defined above
    openRef.current = socket.current.onOpen(onOpen);
    closeRef.current = socket.current.onClose(onClose);
    errorRef.current = socket.current.onError(onError);

    socket.current.connect(); // isConnected should be true after this
  }, [accessToken]);

  return { socket: socket.current, isConnected };
};

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { socket, isConnected } = useSocket();
  const channelMap = useRef<SocketChannelMap>(new Map<string, number>());

  useEffect(() => {
    if (!isConnected) {
      channelMap.current.clear();
    }
  }, [isConnected]);

  return (
    <SocketContext.Provider
      value={{ channelMap: channelMap.current, socket, isConnected }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => useContext(SocketContext);
