import { View, StyleSheet } from "react-native";
import { Formik, FormikHelpers } from "formik";
import {
  Button,
  FlatListItemSeparator,
  Form,
  FormikSubmit,
  FormikTextareaField,
  FormikTextInputField,
  FormikSelectField,
  useToast,
} from "@smartrent/ui";
import { useState } from "react";
import { Method } from "badmagic";
import * as yup from "yup";

import { Controller } from "@/modules/controller/types";
import { Site } from "@/modules/site/types";
import {
  getDefaultPathValue,
  MethodOptions,
  PathOptions,
} from "@/modules/controller/configurations/akvx";
import { useChannel } from "@/hooks/useChannel";
import { controllerChannelTopic, Messages } from "@/lib/socket";
import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";

import {
  ControllerResponse,
  ControllerResponseRow,
} from "./shared/ControllerDebugResponse";

interface AkvxDebugPanelProps {
  controller: Controller;
  site: Site;
}

export interface AkuvoxApiPayload {
  method: Method;
  path: string;
  params: string;
}

const validationSchema = yup.object().shape({
  method: yup.string().required().label("Method"),
  path: yup.string().required().label("Path"),
  params: yup.string().required().label("Params"),
});

export const AkvxDebugPanel = ({ controller }: AkvxDebugPanelProps) => {
  const controllerChannel = useChannel(controllerChannelTopic(controller));
  const [replies, setReplies] = useState<Array<ControllerResponse>>([]);
  const [loading, setLoading] = useState(false);
  const [manual, setManual] = useState(false);
  const setToast = useToast();

  useSocketChannelEvent(controllerChannel, Messages.debug_reply, (response) => {
    if (!response.body) return;

    const temp = replies.slice();
    temp.push({
      status: response.status === 200 ? "success" : "error",
      body: JSON.stringify(response.body, null, 2),
      path: response.path,
      timestamp: new Date().toISOString(),
    });
    setReplies(temp);
    setLoading(false);
  });

  const submitForm = (
    values: AkuvoxApiPayload,
    formikHelpers: FormikHelpers<AkuvoxApiPayload>
  ) => {
    if (!controllerChannel || controllerChannel.state != "joined") {
      setToast({
        message: "Socket disconnected, refresh the page and try again.",
        status: "error",
      });
      formikHelpers.setSubmitting(false);
      return;
    }

    try {
      const params = JSON.parse(values.params);
      controllerChannel.push(Messages.debug_send_request, {
        ...values,
        params,
      });
      setLoading(true);
    } catch (err) {
      formikHelpers.setFieldError("params", "Invalid JSON");
    }
    formikHelpers.setSubmitting(false);
  };

  return (
    <View style={styles.root}>
      <View>
        <Formik
          initialValues={{
            method: "GET",
            path: "/api/system/info",
            params: "{}",
          }}
          onSubmit={submitForm}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => {
            const defaultPathParams = getDefaultPathValue(values)?.defaultValue;

            return (
              <Form>
                <View style={styles.formContainer}>
                  <View style={styles.formHStack}>
                    <AkvxInput
                      style={styles.formHInput}
                      manual={manual}
                      name="method"
                      label="Method"
                      options={MethodOptions}
                    />
                    <AkvxInput
                      style={styles.formHInput}
                      manual={manual}
                      name="path"
                      label="Path"
                      options={PathOptions(values.method)}
                    />
                    <View style={styles.formHSubmit}>
                      <CustomButton
                        onPress={() => {
                          setManual(!manual);
                        }}
                        manual={manual}
                      />
                    </View>

                    {values.method === "POST" && !!defaultPathParams && (
                      <View style={styles.formHSubmit}>
                        <Button
                          size="large"
                          onPress={() => {
                            setFieldValue(
                              "params",
                              JSON.stringify(defaultPathParams, null, 2)
                            );
                          }}
                        >
                          Default Value
                        </Button>
                      </View>
                    )}
                    {replies.length > 0 && (
                      <View style={styles.formHSubmit}>
                        <Button
                          size="large"
                          onPress={() => {
                            setReplies([]);
                          }}
                        >
                          Clear
                        </Button>
                      </View>
                    )}
                    <View style={styles.formHSubmit}>
                      <FormikSubmit size="large" disabled={loading} />
                    </View>
                  </View>
                  <FormikTextareaField name="params" label="Params" />
                </View>
              </Form>
            );
          }}
        </Formik>
      </View>
      <View style={styles.replies}>
        {replies.map((reply, i) => {
          return (
            <View key={i}>
              <ControllerResponseRow {...reply} />
              <FlatListItemSeparator />
            </View>
          );
        })}
      </View>
    </View>
  );
};

const CustomButton = ({ onPress, manual }: any) => {
  const text = manual ? "Dropdown" : "Custom";
  return (
    <Button size="large" onPress={onPress}>
      {text}
    </Button>
  );
};

const AkvxInput = ({ style, name, label, options, manual }: any) => {
  if (manual) {
    return <FormikTextInputField style={style} label={label} name={name} />;
  }
  return (
    <FormikSelectField
      style={style}
      label={label}
      name={name}
      options={options}
    />
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "column",
  },
  replies: {
    padding: 16,
    flexDirection: "column-reverse",
  },
  formContainer: {
    padding: 8,
  },
  formHStack: {
    flexDirection: "row",
  },
  formHInput: {
    padding: 4,
    flexGrow: 1,
  },
  formHSubmit: {
    padding: 4,
  },
});
