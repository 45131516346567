import {
  FormikTextInputField,
  Typography,
  FilterProps,
  Table,
  ListQueryResponse,
  useTableQuery,
} from "@smartrent/ui";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";
import { usePermissions } from "@/context/PolicyContext";

import { createActionsColumn, CreateButton } from "../base/table/utils";

import { SiteNotificationRecipientQueries } from "./queries";
import { SiteNotificationRecipient } from "./types";

interface SiteNotificationRecipientTableProps {
  site_id: number;
}

export const SiteNotificationRecipientTable = ({
  site_id,
}: SiteNotificationRecipientTableProps) => {
  const drawer = useAppDrawer();

  const { confirm } = useDialog();
  const [deleteRecipient] =
    SiteNotificationRecipientQueries.useDeleteMutation();

  const { canView, canCreate, canDelete } = usePermissions(
    SiteNotificationRecipientQueries
  );

  const tableProps = useTableQuery<
    any,
    SiteNotificationRecipient,
    ListQueryResponse<SiteNotificationRecipient>
  >({
    fetch: SiteNotificationRecipientQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      SiteNotificationRecipientQueries.queryKey,
      {},
      {
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        site_id: site_id,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "email",
        header: "Email",
        render: ({ row }) => {
          return <Typography>{row.email}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      createActionsColumn<SiteNotificationRecipient>({
        shouldAdjustForPressIcon: canView,
        maxWidth: 64,
        canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this recipient?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });

          if (confirmed) {
            deleteRecipient(row);
          }
        },
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<SiteNotificationRecipient>
      title={"Recipients"}
      noRecordsText={`No Recipients Found`}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() =>
            drawer.push(SiteNotificationRecipientQueries.queryKey, {
              site_id: site_id,
            })
          }
          text={"Add Site Notification Recipients"}
        />
      }
      {...tableProps}
    />
  );
};
