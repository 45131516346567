import { useLocation } from "react-router-dom";
import {
  Button,
  FormikPasswordInputField,
  FormikTextInputField,
  Link,
  StatusBanner,
} from "@smartrent/ui";

import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useCallback, useContext } from "react";
import { StyleSheet, View } from "react-native";
import * as yup from "yup";

import { AuthContext } from "@/context/Auth";
import {
  AuthLoginRequest,
  SignedIdTokenAuthRequest,
} from "@/modules/auth/types";

import { qsFromLocation } from "@/lib/helpers";

import { AuthQueries } from "./queries";

const initialValues = {
  email: "",
  password: "",
};

interface ErrorBannerProps {
  isError: boolean;
  error: any;
  onDismiss: () => void;
}

const validationSchema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

const ErrorBanner: React.FC<React.PropsWithChildren<ErrorBannerProps>> = ({
  isError,
  error,
  onDismiss,
}) => {
  if (!isError) {
    return null;
  }
  const authErrorText = "Incorrect email or password.";
  const unknownErrorText = "Something went wrong. Please try again.";
  const isAuthError = error.response?.status === 401;

  const errorText = isAuthError ? authErrorText : unknownErrorText;

  return (
    <View style={styles.marginBottom16}>
      <StatusBanner
        title={errorText}
        actions={[{ label: "Dismiss", onPress: onDismiss }]}
        status="error"
      />
    </View>
  );
};

export const LoginForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation();
  const [login, { isLoading, isError, reset, error }] =
    AuthQueries.useLoginMutation();
  const { setState } = useContext(AuthContext);

  const handleSubmit = useCallback(
    async (
      values: AuthLoginRequest,
      _helpers: FormikHelpers<AuthLoginRequest> | null
    ) => {
      login(values)
        .then((res) => {
          setState({
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
            user: res.data.user,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [setState, login]
  );

  // If an id_token from CentralStation is in the querystring, initiate a login request immediately using
  // that id token. The backend will validate that it's a valid id_token.
  useEffect(() => {
    const qs = qsFromLocation(location);
    if (!qs.id_token) {
      return;
    }

    handleSubmit({ id_token: qs.id_token } as SignedIdTokenAuthRequest, null);
  }, [location, handleSubmit]);

  return (
    <Formik<AuthLoginRequest>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isValid, isSubmitting }) => (
        <Form>
          <View>
            <ErrorBanner isError={isError} error={error} onDismiss={reset} />
            <View style={styles.marginBottom16}>
              <FormikTextInputField
                name="email"
                label="Email Address"
                disabled={isLoading || isSubmitting}
                required
                textInputProps={{ nativeID: "email" }} // id is necessary for Qualys Tools used in security environment
              />
            </View>
            <View style={styles.marginBottom16}>
              <FormikPasswordInputField
                name="password"
                label="Password"
                disabled={isLoading || isSubmitting}
                required
                textInputProps={{ nativeID: "password" }} // id is necessary for Qualys Tools used in security environment
                submitOnEnter={true}
              />
            </View>

            <Button
              disabled={(!isValid && !isSubmitting) || isLoading}
              onPress={submitForm}
              style={[styles.submit, styles.marginBottom12]}
            >
              Sign in
            </Button>

            <Link href={"/session/forgot-password"} style={styles.link}>
              Forgot your password?
            </Link>
          </View>
        </Form>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  link: {
    textDecorationLine: "underline",
    textAlign: "center",
  },
  submit: {
    flexGrow: 0,
  },
  marginBottom12: {
    marginBottom: 12,
  },
  marginBottom16: {
    marginBottom: 16,
  },
});
