import { useState, useEffect } from "react";

import {
  HStack,
  ListItemContainer,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitle,
  Typography,
  VStack,
  useTheme,
} from "@smartrent/ui";
import { View } from "react-native";

import { formatRelative } from "date-fns";

import { Door as DoorIcon } from "@smartrent/icons";

import { capitalizeFirstLetter, humanize } from "@/lib/helpers";
import { Site } from "@/modules/site/types";
import { Door } from "@/modules/door/types";
import { DATE_TIME_FORMAT, formatDateTime } from "@/lib/formatters";
import { useChannel } from "@/hooks/useChannel";
import { doorChannelTopic, Messages } from "@/lib/socket";
import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";

import { ControllerNameAndStatusIndicator } from "@/modules/controller/components/ControllerStatusIndicator";

import { HubNameAndStatusIndicator } from "@/modules/hub/components/HubStatusIndicator";

import { UnlockedScheduleListItem } from "../UnlockedScheduleListItem";

import { InformationItem } from "../InformationItem";

import { DoorInformationPanelActions } from "./DoorInformationPanelActions";

interface DoorInformationPanelProps {
  site: Site;
  door: Door;
}

export const DoorInformationPanel: React.FC<
  React.PropsWithChildren<DoorInformationPanelProps>
> = ({ site, door }) => {
  const doorChannel = useChannel(doorChannelTopic(door.id));
  const [liveDoor, setLiveDoor] = useState<Door>(door);

  const { spacing } = useTheme();

  useSocketChannelEvent(doorChannel, Messages.door_changed, (response) => {
    setLiveDoor(response);
  });

  useEffect(() => {
    setLiveDoor(door);
  }, [door]);

  return (
    <Panel>
      <PanelHeader
        EndAdornment={
          <DoorInformationPanelActions door={liveDoor} site={site} />
        }
      >
        <HStack spacing={8} align="center">
          <DoorIcon />
          <PanelHeaderTitle title={`${liveDoor.name}`} />{" "}
        </HStack>
      </PanelHeader>
      <PanelBody>
        <View>
          <InformationItem
            label="Last Synced At"
            value={
              liveDoor.synced_at
                ? formatDateTime(
                    `${liveDoor.synced_at}Z` as string,
                    site.timezone as string,
                    DATE_TIME_FORMAT
                  )
                : liveDoor.synced_at
            }
          />
          <ListItemContainer>
            <VStack spacing={4} style={{ paddingVertical: spacing.md }}>
              <Typography color="primary" type="title5">
                Controller
              </Typography>
              {liveDoor?.controller ? (
                <ControllerNameAndStatusIndicator
                  controller={liveDoor.controller}
                />
              ) : null}
            </VStack>
          </ListItemContainer>

          <ListItemContainer>
            <VStack spacing={4} style={{ paddingVertical: spacing.md }}>
              <Typography color="primary" type="title5">
                Hub
              </Typography>
              {liveDoor?.controller?.hub ? (
                <HubNameAndStatusIndicator hub={liveDoor.controller.hub} />
              ) : null}
            </VStack>
          </ListItemContainer>

          <InformationItem label="Remote ID" value={liveDoor.remote_id} />
          <InformationItem
            label="Default Mode"
            value={humanize(liveDoor.default_mode)}
          />
          <InformationItem
            label="Current Mode"
            value={humanize(liveDoor.mode)}
          />
          {liveDoor.mode_exp &&
          new Date(`${liveDoor.mode_exp}Z`) > new Date() ? (
            <InformationItem
              label="Temp Mode Reset"
              value={capitalizeFirstLetter(
                formatRelative(new Date(`${liveDoor.mode_exp}Z`), new Date())
              )}
            />
          ) : null}

          <InformationItem
            label="Configuration"
            value={humanize(liveDoor.configuration)}
          />
          <InformationItem label="Reader" value={liveDoor.reader?.name} />
          <InformationItem label="Strike" value={liveDoor.strike?.name} />
          <InformationItem label="Rex 1" value={liveDoor.rex1?.name} />
          <InformationItem label="Rex 2" value={liveDoor.rex2?.name} />
          <InformationItem
            label="Strike Mode"
            value={humanize(liveDoor?.strike_mode as string)}
          />
          <InformationItem label="Strike Min" value={liveDoor.strike_min} />
          <InformationItem label="Strike Max" value={liveDoor.strike_max} />

          <UnlockedScheduleListItem door={liveDoor} site={site} />
          <InformationItem
            label="Created"
            value={formatDateTime(
              liveDoor.inserted_at as string,
              site.timezone as string,
              "dd-MM-yyyy h:mm a"
            )}
          />
          <InformationItem
            label="Protocol (Controller)"
            value={liveDoor.controller?.protocol}
          />
        </View>
      </PanelBody>
    </Panel>
  );
};
