import {
  VStack,
  FormikSelectField,
  FormikNumberInputField,
  Typography,
  FormikCheckbox,
} from "@smartrent/ui";
import { Form, FormikProps } from "formik";
import * as yup from "yup";
import { StyleSheet, View } from "react-native";

import { BaseForm, FormProps } from "../base/Form";
import { ControllerProtocols } from "../controller/types";

import { InputQueries } from "./queries";
import {
  IcvtNumber,
  IcvtOptions,
  Input,
  InputType,
  ValidIcvtOptions,
  ValidInputSampleStates,
} from "./types";
import { FormikInputSamples } from "./components/FormikInputSample";

export const InputForm = ({ initialValues, params }: FormProps<Input>) => {
  if (initialValues.panel?.controller?.protocol === ControllerProtocols.mpl) {
    return <MPLInputForm initialValues={initialValues} params={params} />;
  }

  if (initialValues.panel?.controller?.protocol === ControllerProtocols.smrtp) {
    return <SmrtpInputForm initialValues={initialValues} params={params} />;
  }

  return (
    <View style={styles.invalidControllerContainer}>
      <Typography>This input isn't modifiable</Typography>
    </View>
  );
};

const MPLInputForm = ({ initialValues, params }: FormProps<Input>) => {
  const validationSchema = yup.object().shape({
    icvt_num: yup.number().required().oneOf(ValidIcvtOptions).label("Scan"),
    debounce: yup.number().required().min(1).max(15).label("Debounce"),
    hold_time: yup.number().required().min(0).max(15).label("Hold Time"),
  });

  return (
    <BaseForm<Input>
      initialValues={{
        debounce: initialValues?.debounce,
        hold_time: initialValues?.hold_time,
        icvt_num: initialValues?.icvt_num,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={InputQueries}
      params={params}
    >
      {() => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikSelectField
                required
                name={"icvt_num"}
                label={"Scan"}
                options={IcvtOptions}
              />
              <FormikNumberInputField
                required
                name="debounce"
                label="Debounce"
              />
              <FormikNumberInputField
                required
                name="hold_time"
                label="Hold Time"
              />
            </VStack>
          </Form>
        );
      }}
    </BaseForm>
  );
};

const SmrtpInputForm = ({ initialValues, params }: FormProps<Input>) => {
  const validationSchema = yup.object().shape({
    icvt_num: yup.number().required().label("Scan").oneOf(ValidIcvtOptions),
    supervised: yup.boolean(),
    samples: yup.array().when("supervised", {
      is: true,
      then: yup.array(
        yup.object({
          low: yup.number().required().min(0, "Must be 0 or greater"),
          high: yup
            .number()
            .required()
            .moreThan(
              yup.ref("low"),
              "High value must be greater than Low value"
            )
            .max(10000, "Must be 10,000 or less"),
          state: yup
            .string()
            .required("You must select a Sample State")
            .oneOf(ValidInputSampleStates),
        })
      ),
    }),
  });

  return (
    <BaseForm<Input>
      initialValues={{
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={InputQueries}
      params={params}
    >
      {({ values }: FormikProps<Input>) => (
        <Form>
          <VStack spacing={16}>
            {initialValues.input_type !== InputType.aux ? (
              <FormikCheckbox name="supervised" label="Supervised" />
            ) : null}
            {!values.supervised ? (
              <FormikSelectField
                name={"icvt_num"}
                label={"Scan"}
                options={[
                  {
                    value: IcvtNumber.closed,
                    label: "Normally Closed",
                  },
                  {
                    value: IcvtNumber.open,
                    label: "Normally Open",
                  },
                ]}
              />
            ) : (
              <FormikInputSamples name="samples" />
            )}
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};

const styles = StyleSheet.create({
  invalidControllerContainer: { padding: 16, alignItems: "center" },
});
