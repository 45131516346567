import {
  VStack,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerActions,
  Button,
} from "@smartrent/ui";

import {
  Form,
  FormOnSubmit,
  FormSubmit,
  FormTextInputField,
} from "@smartrent/forms";

import * as yup from "yup";

import { useCallback } from "react";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { Door } from "../../door/types";
import { FloorQueries } from "../queries";
import { Floor } from "../types";

const validationSchema = yup.object().shape({
  name: yup.string().max(40).required().label("Name"),
});

interface EditFloorFormDrawerProps {
  initialValues?: Door;
}

export const EditFloorFormDrawer: React.FC<
  React.PropsWithChildren<EditFloorFormDrawerProps>
> = ({ initialValues }) => {
  const { activeRoute, reset, pop } = useAppDrawer();

  const [update] = FloorQueries.useUpdateMutation();

  const handleSubmit = useCallback<FormOnSubmit<Partial<Floor>>>(
    async (values, helpers) => {
      pop();
      try {
        await update({ id: values.id as number, values: values as Floor });
        pop();
      } catch (err: any) {
        const [{ description, field }] = err.response.data.errors;
        helpers.setFieldError && helpers.setFieldError(field, description);
      }
    },
    [update, pop]
  );
  return (
    <Form<Partial<Floor>>
      // remove 'floors' from initialValues to prevent overwriting the value in backend
      initialValues={{ ...initialValues }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Drawer
        testID="edit-floor-form-drawer"
        open={!!activeRoute}
        onClose={reset}
        anchor="right"
      >
        <DrawerHeader title={initialValues?.name} subtitle="Edit Floor" />

        <DrawerContent>
          <VStack spacing={8}>
            <FormTextInputField name="name" label="Name" required />
          </VStack>
        </DrawerContent>

        <DrawerActions>
          <Button variation="plain" onPress={pop} color="grayscale">
            Cancel
          </Button>
          <FormSubmit label="Save" />
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
