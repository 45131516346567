import React, { useCallback } from "react";
import { View, StyleSheet } from "react-native";
import * as Sentry from "@sentry/react";
import { Button } from "@smartrent/ui";

import { NoRecords } from "./NoRecords";

export const SentryErrorBoundary: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  if (process.env.SENTRY_DSN === "") {
    return <>{children}</>;
  }

  return (
    <Sentry.ErrorBoundary fallback={<SentryError />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

const SentryError: React.FC<React.PropsWithChildren<unknown>> = () => {
  const handleReload = useCallback(() => {
    window.location.reload();

    return false;
  }, []);

  return (
    <View style={styles.error}>
      <NoRecords title="Something Went Wrong" />
      <Button onPress={handleReload}>Reload Page</Button>
    </View>
  );
};

const styles = StyleSheet.create({
  error: {
    flex: 1,
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
  },
});
