import { useContext, useEffect } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderTitle } from "@smartrent/ui";

import { Site } from "@/modules/site/types";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";
import { SiteNotificationRecipientTable } from "@/modules/site-notification-recipients/SiteNotificationRecipientsTable";

interface NotificationSiteSettingsPage {
  site: Site;
}

export const NotificationSiteSettingsPage = ({
  site,
}: NotificationSiteSettingsPage) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Site Notification Settings`,
      to: Paths.siteSettingsGeneralPath(site.id),
    });
    addBreadcrumb({
      label: `Site Settings`,
      to: Paths.siteSettingsPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return (
    <Panel>
      <PanelHeader>
        <PanelHeaderTitle title={`Site Notification Settings`} />
      </PanelHeader>
      <PanelBody>
        <SiteNotificationRecipientTable site_id={site.id} />
      </PanelBody>
    </Panel>
  );
};
