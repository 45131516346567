import { View, StyleSheet } from "react-native";
import { Formik, FormikHelpers } from "formik";
import {
  Button,
  FlatListItemSeparator,
  Form,
  FormikSubmit,
  FormikSelectField,
  useToast,
  FormikNumberInputField,
  FormikTextInputField,
  VStack,
} from "@smartrent/ui";
import { useCallback, useState } from "react";
import * as yup from "yup";

import { Controller } from "@/modules/controller/types";
import { Site } from "@/modules/site/types";
import { useChannel } from "@/hooks/useChannel";
import {
  controllerChannelTopic,
  hubChannelTopic,
  Messages,
} from "@/lib/socket";
import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";
import { Hub } from "@/modules/hub/types";
import { humanize } from "@/lib/helpers";

import {
  MPLCommand,
  MPLCommandParams,
  CommandParamType,
  MPLCommandOptions,
} from "@/modules/controller/configurations/mpl/commands";

import {
  ControllerResponse,
  ControllerResponseRow,
} from "./shared/ControllerDebugResponse";

interface MPLDebugPanelProps {
  controller: Controller;
  site: Site;
}

const validationSchema = yup.object().shape({
  command: yup.string().nullable().required().label("Command"),
});

interface MPLCommandPayload {
  command: MPLCommand | null;
  params: any;
}

export const MPLDebugPanel = ({ controller, site }: MPLDebugPanelProps) => {
  const hubChannel = useChannel(hubChannelTopic(controller.hub as Hub));
  const controllerChannel = useChannel(controllerChannelTopic(controller));

  const [selectedCommand, setSelectedCommand] = useState<MPLCommand | null>(
    null
  );
  const [replies, setReplies] = useState<Array<ControllerResponse>>([]);
  const setToast = useToast();

  useSocketChannelEvent(controllerChannel, Messages.debug_reply, (response) => {
    if (!response.body) return;
    const temp = replies.slice();
    temp.push({
      status: response.status,
      body: JSON.stringify(response.body, null, 2),
      path: response.path,
      timestamp: new Date().toISOString(),
    });
    setReplies(temp);
  });

  useSocketChannelEvent(hubChannel, Messages.controller_reply, (response) => {
    const temp = replies.slice();
    const status =
      response.status === 1
        ? "success"
        : response.status === 0
          ? "error"
          : "info";
    temp.push({
      status,
      body: JSON.stringify(response, null, 2),
      path: response.reference,
      timestamp: new Date().toISOString(),
    });
    setReplies(temp);
  });

  const defaultFormValues = useCallback(() => {
    if (!selectedCommand)
      return {
        command: null,
        site_id: site.id,
        controller_id: controller.id,
        params: {},
      };
    const selectedCommandParams = MPLCommandParams[selectedCommand];

    if (!selectedCommandParams) return { command: null, params: {} };
    if (!selectedCommandParams?.params) return { command: null, params: {} };

    const params: any = {};
    selectedCommandParams.params.forEach((param) => {
      params[param.name] = param?.default || null;
    });

    return {
      command: selectedCommand,
      site_id: site.id,
      controller_id: controller.id,
      params,
    };
  }, [selectedCommand, controller.id, site.id]);

  const submitForm = useCallback(
    (
      values: MPLCommandPayload,
      formikHelpers: FormikHelpers<MPLCommandPayload>
    ) => {
      if (controllerChannel?.state !== "joined") {
        setToast({
          message: "Socket disconnected, refresh the page and try again.",
          status: "error",
        });
        formikHelpers.setSubmitting(false);
        return;
      }

      controllerChannel.push(Messages.debug_send_request, values);
      formikHelpers.setSubmitting(false);
    },
    [controllerChannel, setToast]
  );

  const AdditionalCommandParams = useCallback(
    ({ helpers }: { helpers: any }) => {
      if (!selectedCommand) return null;
      const selectedCommandParams = MPLCommandParams[selectedCommand];

      if (!selectedCommandParams) return null;
      if (!selectedCommandParams.form && !selectedCommandParams?.params)
        return null;

      if (selectedCommandParams.form) {
        return <selectedCommandParams.form {...helpers} />;
      }

      if (!selectedCommandParams?.params) return null;

      return (
        <View style={styles.additionalParamsContainer}>
          {selectedCommandParams.params.map((param, index) => {
            return (
              <View key={index} style={styles.additionalParamsStyles}>
                {param.component ? (
                  <param.component
                    key={`${param.name}-${index}`}
                    label={param.label ? param.label : humanize(param.name)}
                    name={`params.${param.name}`}
                    {...helpers}
                  />
                ) : param.type === CommandParamType.number ? (
                  <FormikNumberInputField
                    key={`${param.name}-${index}`}
                    name={`params.${param.name}`}
                    label={param.label ? param.label : humanize(param.name)}
                  />
                ) : (
                  <FormikTextInputField
                    key={`${param.name}-${index}`}
                    name={`params.${param.name}`}
                    label={param.label ? param.label : humanize(param.name)}
                  />
                )}
              </View>
            );
          })}
        </View>
      );
    },
    [selectedCommand]
  );

  return (
    <View style={styles.root}>
      <View>
        <Formik<MPLCommandPayload>
          initialValues={defaultFormValues()}
          onSubmit={submitForm}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {(helpers) => {
            if (helpers.values.command != selectedCommand)
              setSelectedCommand(helpers.values.command);

            return (
              <Form>
                <View style={styles.formContainer}>
                  <View style={styles.formHStack}>
                    <FormikSelectField
                      style={styles.formHInput}
                      name="command"
                      label="Command"
                      options={MPLCommandOptions}
                    />
                    {replies.length > 0 && (
                      <View style={styles.formHSubmit}>
                        <Button
                          size="large"
                          onPress={() => {
                            setReplies([]);
                          }}
                        >
                          Clear
                        </Button>
                      </View>
                    )}
                    <View style={styles.formHSubmit}>
                      <FormikSubmit size="large" />
                    </View>
                  </View>
                  <AdditionalCommandParams helpers={helpers} />
                </View>
              </Form>
            );
          }}
        </Formik>
      </View>
      <View style={styles.replies}>
        {replies.map((reply, i) => {
          return (
            <VStack key={i}>
              <ControllerResponseRow {...reply} />
              <FlatListItemSeparator />
            </VStack>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "column",
  },
  replies: {
    padding: 16,
    flexDirection: "column-reverse",
  },
  formContainer: {
    padding: 8,
  },
  formHStack: {
    flexDirection: "row",
  },
  formHInput: {
    padding: 4,
    flexGrow: 1,
  },
  formHSubmit: {
    padding: 4,
  },
  additionalParamsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  additionalParamsStyles: {
    padding: 4,
  },
});
