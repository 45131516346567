import {
  ActivityIndicator,
  HStack,
  ListItemContainer,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitle,
  Typography,
  useTheme,
  VStack,
} from "@smartrent/ui";
import { View } from "react-native";

import { useState } from "react";

import { Elevator as ElevatorIcon } from "@smartrent/icons";

import { humanize } from "@/lib/helpers";
import { Site } from "@/modules/site/types";
import { Door, DoorConfigurations } from "@/modules/door/types";
import { formatDateTime } from "@/lib/formatters";

import { useChannel } from "@/hooks/useChannel";
import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";
import { doorChannelTopic, Messages } from "@/lib/socket";

import { Elevator } from "@/modules/elevator/types";

import { ControllerNameAndStatusIndicator } from "@/modules/controller/components/ControllerStatusIndicator";

import { HubNameAndStatusIndicator } from "@/modules/hub/components/HubStatusIndicator";

import { Controller } from "@/modules/controller/types";

import { Hub } from "@/modules/hub/types";

import { ElevatorInformationPanelActions } from "../elevators/ElevatorInformationPanelActions";
import { InformationItem } from "../InformationItem";
interface ElevatorInformationPanelProps {
  site: Site;
  elevator: Elevator;
}

export const ElevatorInformationPanel = ({
  site,
  elevator,
}: ElevatorInformationPanelProps) => {
  const doorChannel = useChannel(doorChannelTopic(elevator.id));
  const [liveElevator, setLiveElevator] = useState<Door | null>(null);

  const { spacing } = useTheme();

  useSocketChannelEvent(doorChannel, Messages.door_changed, (response) => {
    setLiveElevator(response);
  });

  return (
    <Panel>
      <PanelHeader
        EndAdornment={
          !(liveElevator || elevator) ? (
            <ActivityIndicator />
          ) : (
            <ElevatorInformationPanelActions
              site={site}
              elevator={liveElevator || elevator}
            />
          )
        }
      >
        <HStack spacing={8} align="center">
          <ElevatorIcon />
          <PanelHeaderTitle
            title={`${(liveElevator || elevator)?.name}`}
          />{" "}
        </HStack>
      </PanelHeader>
      <PanelBody>
        <View>
          <InformationItem
            label="Last Synced At"
            value={
              (liveElevator || elevator)?.synced_at
                ? formatDateTime(
                    liveElevator?.synced_at as string,
                    site.timezone as string,
                    "dd-MM-yyyy h:mm a"
                  )
                : (liveElevator || elevator)?.synced_at
            }
          />

          <ListItemContainer>
            <VStack spacing={4} style={{ paddingVertical: spacing.md }}>
              <Typography color="primary" type="title5">
                Controller
              </Typography>
              {(liveElevator || elevator)?.controller ? (
                <ControllerNameAndStatusIndicator
                  controller={
                    (liveElevator || elevator).controller as Controller
                  }
                />
              ) : null}
            </VStack>
          </ListItemContainer>

          <ListItemContainer>
            <VStack spacing={4} style={{ paddingVertical: spacing.md }}>
              <Typography color="primary" type="title5">
                Hub
              </Typography>
              {(liveElevator || elevator)?.controller?.hub ? (
                <HubNameAndStatusIndicator
                  hub={
                    ((liveElevator || elevator).controller as Controller)
                      .hub as Hub
                  }
                />
              ) : null}
            </VStack>
          </ListItemContainer>

          <InformationItem
            label="Default Mode"
            value={humanize((liveElevator || elevator)?.default_mode)}
          />
          <InformationItem
            label="Configuration"
            value={humanize((liveElevator || elevator)?.configuration)}
          />
          <InformationItem
            label="Reader"
            value={(liveElevator || elevator)?.reader?.name}
          />
          <InformationItem
            label="First Output"
            value={(liveElevator || elevator)?.strike?.name}
          />
          {(liveElevator || elevator)?.configuration ===
          DoorConfigurations.elevatorWithFloor ? (
            <View>
              <InformationItem
                label="Floor Input"
                value={(liveElevator || elevator)?.rex1?.name}
              />
              <InformationItem
                label="Activated Output Time"
                value={(liveElevator || elevator)?.strike_min}
              />
            </View>
          ) : null}
          <InformationItem
            label="Number of Floors"
            value={((liveElevator || elevator)?.floors || []).length}
          />
          <InformationItem
            label="Active Push Button Duration"
            value={(liveElevator || elevator)?.strike_max}
          />
          <InformationItem
            label="Created"
            value={formatDateTime(
              (liveElevator || elevator)?.inserted_at as string,
              site.timezone as string,
              "dd-MM-yyyy h:mm a"
            )}
          />
        </View>
      </PanelBody>
    </Panel>
  );
};
